import { useState } from 'react'
import { Modal } from 'components'
import moment from 'moment/moment'
import { Users, BoxSeam } from 'components/icons/outline'
import { lang } from "config"
import { Link } from 'react-router-dom'
import { usePage } from 'contexts/page-context'

const Content = ({ data, closeModal }) => {
    const { configuration } = usePage()

    return (
        <div className="mt-8 space-y-8">
            <div className="overflow-x-auto border border-neutral-200 rounded-xl">
                <table className="min-w-full overflow-x-auto divide-y divide-neutral-200">
                    <thead className="bg-neutral-50 rounded-t-3xl">
                        <tr>
                            <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">Purchase Date</th>
                            <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">DoS Date</th>
                            {configuration('site') === 'go' && (
                                <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">Outlet ID</th>
                            )}
                            <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">Purchase Order ID</th>
                            <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">Purchase Stock</th>
                            <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">Current Stock</th>
                            <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">Amount</th>
                            <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">HPP</th>
                            {configuration('site') === 'go' && (
                                <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">Maximum Retail Price</th>
                            )}
                        </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-neutral-200">
                        {data.length === 0 && (
                            <tr className="text-center">
                                <td colSpan="10" className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                    {lang.no_data}
                                </td>
                            </tr>
                        )}
                        {data.length !== 0 && data?.map((row, index) => (
                            <tr key={row}>
                                <td className="px-6 py-4 text-xs font-medium text-neutral-900 whitespace-nowrap">
                                    {moment(row.purchase_date).format('MMMM D, YYYY')}
                                </td>
                                <td className="px-6 py-4 text-xs font-medium text-neutral-900 whitespace-nowrap">
                                    {row.dos_date ? moment(row.dos_date).format('MMMM D, YYYY') : '-'}
                                </td>
                                {configuration('site') === 'go' && (
                                    <td className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                        {row.outlet_id ?? '-'}
                                    </td>
                                )}
                                <td className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                    {row.purchase_order_id ?? '-'}
                                </td>
                                <td className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                    {row.purchase_stock}
                                </td>
                                <td className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                    {row.current_stock}
                                </td>
                                <td className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                    {Intl.NumberFormat('id-Id', { style: 'currency', currency: "IDR" }).format(row.amount)}
                                </td>
                                <td className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                    {Intl.NumberFormat('id-Id', { style: 'currency', currency: "IDR" }).format(row.hpp_amount)}
                                </td>
                                {configuration('site') === 'go' && (
                                    <td className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                        {Intl.NumberFormat('id-Id', { style: 'currency', currency: "IDR" }).format(row.maximum_retail_price)}
                                    </td>
                                )}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <div className="text-xs">
                <button onClick={closeModal} className="items-center px-6 py-3 text-white transition bg-neutral-800 rounded-xl active:hover:scale-90">
                    <span>{lang.close}</span>
                </button>
            </div>
        </div>
    )
}

const StockList = ({ data, largeButton = false }) => {
    const [isOpen, setIsOpen] = useState(false)

    const openModal = () => setIsOpen(true)
    const closeModal = () => setIsOpen(false)

    return (
        <>
            {largeButton ? (
                <button type="button" onClick={openModal} className="block w-full px-2 py-2 mt-1 text-sm transition border border-neutral-200 focus:outline-none rounded-xl focus:border-neutral-400 focus:ring focus:ring-neutral-200">
                    Lihat Stock {data?.length !== 0 && `(${data.length})`}
                </button>
            ) : (
                <button onClick={openModal} className="inline-flex items-center space-x-2 text-xs underline">
                    <div>
                        Lihat Stock
                    </div>
                </button>
            )}
            <Modal isOpen={isOpen} size="6xl" onClose={closeModal} title="List Stock" content={<Content data={data} closeModal={closeModal} />} />
        </>
    )
}

export default StockList
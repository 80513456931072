import { useEffect, useState } from 'react'
import { Modal } from 'components'
import ErrorMessage from 'components/forms/error-message'
import { lang } from 'config'
import _ from 'lodash'
import { Plus } from 'components/icons/outline'
import axios from 'axios'
import { toast } from 'react-hot-toast'
import nProgress from 'nprogress'
import Label from 'components/forms/label'
import useAttributeGroupRepositories from 'repositories/attribute-group'
import useAttributeRepositories from 'repositories/attribute'
import InputDate from 'components/forms/input-date'
import moment from 'moment'
import InputAmount from 'components/forms/input-amount'
import useRewardRepositories from 'repositories/reward'
import SelectDescription from 'components/forms/select-description'

const Content = ({ data, onSubmit }) => {
    const { data: rewardSelection, isLoading: isLoadingRewardSelection } = useRewardRepositories({
        paginate: false
    })

    const [reward, setReward] = useState()
    const [rewardId, setRewardId] = useState()
    const [from, setFrom] = useState()
    const [to, setTo] = useState()

    const [excluded, setExcluded] = useState([])

    const [errors, setErrors] = useState({})

    useEffect(() => {
        if (data) {
            var excludes = []

            data.forEach((row) => {
                const start = new Date(row.from_date)
                const end = new Date(row.to_date)

                excludes.push({ start: start.setDate(start.getDate() - 1), end })
            })

            setExcluded(excludes)
        }
    }, [data])

    useEffect(() => {
        if (rewardId && !isLoadingRewardSelection) {
            setReward(rewardSelection?.find((row) => row.id === rewardId))
        }
    }, [rewardId, isLoadingRewardSelection])

    const handleSubmit = (e) => {
        e.preventDefault()

        if (!nProgress.isStarted()) {
            nProgress.start()

            toast.loading("Loading", {
                id: "submit"
            })

            axios.post(`${process.env.REACT_APP_INVENTORY_API_URL}/product/unit/validate`, {
                type: 'reward',
                reward_type_id: rewardId,
                from_date: from,
                to_date: to
            }).then((response) => {
                toast.success(response.data.message, {
                    id: "submit"
                })

                onSubmit({
                    reward,
                    from_date: from,
                    to_date: to
                })

                nProgress.done()
            }).catch((error) => {
                if (error.response.status === 422) {
                    setErrors(error.response.data.errors)
                }
                toast.error(error.response.data.message, {
                    id: "submit"
                })

                console.log(error.response)

                nProgress.done()
            })
        } else {
            toast.error("Previous action is loading")
        }
    }

    return (
        <div className="mt-8 space-y-8">
            <div className="space-y-4">
                <div className="grid grid-cols-2 gap-4">
                    <div>
                        <Label htmlFor="reward" value="Reward" />
                        <SelectDescription onChange={(value) => setRewardId(value)} selection={rewardSelection} isLoading={isLoadingRewardSelection} value={rewardId} keyValue={(value) => value.id} title={(value) => value.name} placeholder="Select Reward" error={errors.reward_type_id} />
                        <ErrorMessage error={errors.reward_type_id} />
                    </div>

                    <div></div>

                    <div>
                        <Label htmlFor="from" value="From" />
                        <InputDate excludeDateIntervals={excluded} error={errors.from_date} id="from" maxDate={Date.parse(to)} onChange={(value) => setFrom(moment(value).format('Y-MM-DD'))} value={Date.parse(from)} selected={Date.parse(from)} />
                        <ErrorMessage error={errors.from_date} />
                    </div>

                    <div>
                        <Label htmlFor="to" value="To" />
                        <InputDate excludeDateIntervals={excluded} error={errors.to_date} id="to" minDate={Date.parse(from)} onChange={(value) => setTo(moment(value).format('Y-MM-DD'))} value={Date.parse(to)} selected={Date.parse(to)} />
                        <ErrorMessage error={errors.to_date} />
                    </div>
                </div>
            </div>
            <div className="text-xs">
                <button onClick={handleSubmit} className="items-center px-6 py-3 text-white transition bg-neutral-800 rounded-xl active:hover:scale-90">
                    <span>{lang.save}</span>
                </button>
            </div>
        </div>
    )
}

const Add = ({ data, onSubmit }) => {
    const [isOpen, setIsOpen] = useState(false)

    const openModal = () => setIsOpen(true)
    const closeModal = () => setIsOpen(false)

    const handleSubmit = (data) => {
        onSubmit(data)

        closeModal()
    }

    return (
        <>
            <button type="button" onClick={openModal} className="inline-flex items-center px-4 py-3 space-x-2 transition border border-neutral-200 bg-neutral-50 rounded-xl active:hover:scale-90">
                <Plus className="w-4 h-4" stroke={1.5} />
                <span>{lang.create}</span>
            </button>
            <Modal isOpen={isOpen} onClose={closeModal} title={`${lang.create} ${lang.data}`} content={<Content data={data} onSubmit={handleSubmit} />} />
        </>
    )
}

export default Add
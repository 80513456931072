import { useEffect, useRef, useState } from "react"
import { Link, useLoaderData, useLocation, useNavigate } from "react-router-dom"
import { motion } from "framer-motion"

import AppLayout from "layouts/app-layout"
import Transition from "components/transition"
import { lang } from "config"
import { useAuth } from "contexts/auth-context"
import nProgress from "nprogress"
import toast from "react-hot-toast"
import Label from "components/forms/label"
import Input from "components/forms/input"
import Description from "components/forms/description"

export default function Settings() {
    const location = useLocation()
    const navigate = useNavigate()

    const prevData = useRef({})
    const data = useLoaderData() || prevData.current
    const [transition, setTransition] = useState(location.state?.transition)

    useEffect(() => {
        if (location.state?.cache === 'clear') {
            window.history.replaceState({}, document.title)
            navigate(0)
        }
    }, [])

    useEffect(() => {
        if (data) {
            prevData.current = data
        }
    }, [data])

    // Temp
    // const _ = deepdash(lodash)

    const [errors, setErrors] = useState({})

    const id = data?.id

    const [name, setName] = useState(data?.name ?? null)

    const handleSubmit = (e) => {
        e.preventDefault()

        if (!nProgress.isStarted()) {
            // onSubmit({
            //     id,
            //     name
            // })
        } else {
            toast.error("Previous action is loading")
        }
    }

    return (
        <form onSubmit={handleSubmit} className="mt-8 space-y-8">
            <div className="mt-10 sm:mt-0">
                <div className="md:grid md:grid-cols-3 md:gap-6">
                    <div className="md:col-span-1">
                        <div className="px-4 sm:px-0">
                            <h3 className="text-lg font-medium leading-6 text-gray-900">{lang.main_form}</h3>
                            <p className="mt-1 text-sm text-gray-600">{lang.please_fill_the_provided_form_input}</p>
                        </div>
                    </div>
                    <div className="mt-5 md:col-span-2 md:mt-0">
                        <div className="border sm:rounded-xl">
                            <div className="px-4 py-5 sm:p-6">
                                <div className="grid grid-cols-2 gap-4">
                                    <div>
                                        <Label htmlFor="name" value={lang.name} />
                                        <Input placeholder="Uwell" onChange={(e) => setName(e.target.value)} value={name} id="name" error={errors.name} />
                                        <Description error={errors.name} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="mt-8 text-xs text-right">
                <button type="submit" className="items-center px-6 py-3 text-white transition bg-neutral-800 rounded-xl active:hover:scale-90">
                    <span>Save</span>
                </button>
            </div>
        </form>
    )
}
import { useEffect, useState } from "react"
import { Link, useLocation, useNavigate } from "react-router-dom"

import axios from "axios"
import { useAuth } from "contexts/auth-context"
import { useLayoutEffect } from "react"
import Form from "./form"
import nProgress from "nprogress"
import toast from "react-hot-toast"
import { useSWRConfig } from "swr"
import { usePage } from "contexts/page-context"
import { useAuditLogContext } from "contexts/audit-log-context"
import moment from "moment"

export default function Create({ title }) {
    const { user, isSuper } = useAuth()
    const { cache } = useSWRConfig()

    const { queueAuditLog } = useAuditLogContext()

    const { setTransition } = usePage()

    const location = useLocation()
    const navigate = useNavigate()

    const [prevRouteState, setPrevRouteState] = useState({})

    const [errors, setErrors] = useState({})

    useLayoutEffect(() => {
        const getPrevRouteState = async () => {
            setPrevRouteState({
                back: location.state?.back,
                from: location.state?.from,
                transition: location.state?.transition
            })
        }

        setTransition(location.state?.transition)

        getPrevRouteState()
    }, [])

    const handleSubmit = (data) => {
        nProgress.start()

        toast.loading("Loading", {
            id: "submit"
        })

        const url = `${process.env.REACT_APP_INVENTORY_API_URL}/attribute/group`
        const payload = {
            name: data.name,
            created_by: user?.name
        }

        axios.post(url, payload).then((response) => {
            // Queue audit log
            queueAuditLog(url, response.config.method.toUpperCase(), "Create Attribute Group Data", user?.name, payload, null, moment().format('YYYY-MM-DD HH:mm:ss'), response.status)

            // Clear SWR cache
            cache.clear()

            // Navigate page
            navigate({ pathname: '/attribute/group' })

            // Show toast message
            toast.success(response.data.message, {
                id: "submit"
            })
        }).catch((error) => {
            // Assign validation data
            if (error.response.status === 422) {
                setErrors(error.response.data.errors)
            }
            
            // Show toast message
            toast.error(error.response.data.message, {
                ariaProps: {
                    superscript: error.response.status
                },
                id: "submit"
            })
        })
    }

    return (
        <Form errors={errors} onSubmit={handleSubmit} />
    )
}
import { Modal } from "components"
import { useEffect, useRef, useState } from "react"
import { Cropper, ImageRestriction, getTransformedImageSize, retrieveSizeRestrictions } from "react-advanced-cropper"

import "react-advanced-cropper/dist/style.css"
import "styles/cropper.css"
import toast from "react-hot-toast"

const CameraUpIcon = ({ className, stroke }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" className={className} width={24} height={24} viewBox="0 0 24 24" strokeWidth={stroke ?? 1} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
            <path d="M12 20h-7a2 2 0 0 1 -2 -2v-9a2 2 0 0 1 2 -2h1a2 2 0 0 0 2 -2a1 1 0 0 1 1 -1h6a1 1 0 0 1 1 1a2 2 0 0 0 2 2h1a2 2 0 0 1 2 2v3.5"></path>
            <path d="M12 16a3 3 0 1 0 0 -6a3 3 0 0 0 0 6z"></path>
            <path d="M19 22v-6"></path>
            <path d="M22 19l-3 -3l-3 3"></path>
        </svg>
    )
}

const usePrevious = (value) => {
    const ref = useRef()

    useEffect(() => {
        ref.current = value
    }, [value])

    return ref.current
}

const CropImage = ({ src, onSubmit }) => {
    const [current, setCurrent] = useState()

    const cropperRef = useRef(null)

    const handleChange = (data) => {
        // setCurrent(data.getCanvas())
    }

    const handleSubmit = () => {
        onSubmit(cropperRef.current.getCanvas())
    }

    const boundaryProps = (boundary) => {
        return {
            width: boundary.clientWidth,
            height: boundary.clientHeight
        }
    }

    const stencilProps = {
        aspectRatio: 1,
        grid: true
    }

    const defaultSize = ({ imageSize, visibleArea }) => {
        return {
            width: (visibleArea || imageSize).width,
            height: (visibleArea || imageSize).height
        }
    }

    return (
        <div className="mt-8 space-y-8">
            {/* <div className="flex w-full"> */}
            <Cropper boundaryProps={{ boundaryProps }} stencilProps={stencilProps} defaultSize={defaultSize} ref={cropperRef} onChange={handleChange} src={src} />
            {/* </div> */}
            <div className="flex items-center space-x-2 text-xs">
                <button onClick={handleSubmit} type="submit" className="items-center px-6 py-3 text-white transition bg-neutral-800 rounded-xl active:hover:scale-90">
                    <span>Crop</span>
                </button>
            </div>
        </div>
    )
}

const createBlob = (dataURL) => {
    var BASE64_MARKER = ';base64,'
    if (dataURL.indexOf(BASE64_MARKER) == -1) {
        var parts = dataURL.split(',')
        var contentType = parts[0].split(':')[1]
        var raw = decodeURIComponent(parts[1])
        return new Blob([raw], { type: contentType })
    }
    var parts = dataURL.split(BASE64_MARKER)
    var contentType = parts[0].split(':')[1]
    var raw = window.atob(parts[1])
    var rawLength = raw.length

    var uInt8Array = new Uint8Array(rawLength)

    for (var i = 0; i < rawLength; ++i) {
        uInt8Array[i] = raw.charCodeAt(i)
    }

    return new Blob([uInt8Array], { type: contentType });
}

const InputImage = ({ onChange, aspect = "video", disabled, id, name, error, src, alt = "https://placehold.co/1600x900/F5F5F5/404040?font=source-sans-pro&text=Upload%20image%20here" }) => {
    const [selectedImage, setSelectedImage] = useState()
    const [preview, setPreview] = useState(src)
    const [cropped, setCropped] = useState(src)

    const [isOpenCropModal, setIsOpenCropModal] = useState(false)

    const prevSelectedImage = usePrevious(selectedImage)

    // Watch selected image, update preview
    useEffect(() => {
        if (selectedImage !== prevSelectedImage) {
            onChange(selectedImage)

            if (!selectedImage) {
                setPreview(undefined)
            } else {
                const objectURL = URL.createObjectURL(selectedImage)
                setPreview(objectURL)

                // Clean up memory when component unmounted
                return () => URL.revokeObjectURL(objectURL)
            }
        }
    }, [selectedImage])

    const handleInputChange = (e) => {
        if (!e.target.files || e.target.files.length === 0 || !e.target.files[0].name.match(/\.(jpg|jpeg|png)$/)) {
            // setSelectedImage(undefined)
            toast.error("Please select an image.")
        } else {
            setIsOpenCropModal(true)

            const image = e.target.files[0]

            setSelectedImage(image)

            e.target.value = null
        }
    }

    const handleCropImage = (value) => {
        setCropped(value.toDataURL())
        onChange(createBlob(value.toDataURL()))

        closeCropModal()

        // toast.success(JSON.stringify(value))
    }

    const closeCropModal = () => {
        setIsOpenCropModal(false)
    }

    return (
        <>
            <input disabled={disabled} type="file" className="hidden" hidden id={id} name={name} onChange={handleInputChange} />
            <div className="relative mt-1 select-none">
                <img className={`object-cover w-full h-auto border rounded-lg aspect-${aspect} ring ${error ? 'ring-red-100' : 'ring-neutral-100'}`} src={cropped ?? alt} />
                <label htmlFor={id} className={`absolute inset-0 flex items-center justify-center w-full group ${disabled ? '' : 'cursor-pointer'}`}>
                    <div class={`px-4 py-2 text-sm font-semibold text-neutral-700 transition bg-white border border-neutral-300 rounded-lg shadow-sm pointer-events-none bg-opacity-70 ${disabled ? '' : 'group-hover:bg-opacity-90 group-active:border-neutral-300 group-active:ring group-active:ring-neutral-100'}`}>
                        <CameraUpIcon className={`w-6 h-6 transition transform ${disabled ? '' : 'group-active:scale-90'}`} stroke={1.5} />
                    </div>
                </label>
            </div>
            <Modal scaleAnimation={false} size="md" isOpen={isOpenCropModal} onClose={closeCropModal} title={`Crop Image`} content={<CropImage onSubmit={(value) => handleCropImage(value)} src={preview ?? alt} />} />
        </>
    )
}

export default InputImage
import { useState, useEffect } from "react"

import ErrorMessage from "components/forms/error-message"
import lodash from "lodash"
import deepdash from "deepdash"
import { lang } from "config"
import Label from "components/forms/label"
import Input from "components/forms/input"
import useProductModelRepositories from "repositories/product-model"
import Description from "components/forms/description"
import InputImage from "components/forms/input-image"
import { X } from "components/icons/outline"
import { Add as AddBundle } from "./bundle"
import { Add as AddPrice } from "./price"
import { Add as AddDiscount } from "./discount"
import { Add as AddReward } from "./reward"
import { Add as AddAttribute } from "./attribute"
import { Add as AddStock } from "./stock"
import moment from "moment"
import nProgress from "nprogress"
import toast from "react-hot-toast"
import SelectDescription from "components/forms/select-description"
import ErrorTooltip from "components/forms/error-tooltip"
import { matchObjectRegex } from "helpers/array-helper"
import Checkbox from "components/forms/checkbox"
import { PhotoOff } from "components/icons/outline"
import SelectSearchable from "components/forms/select-searchable"
import { usePage } from "contexts/page-context"
import BarcodeScanner from "components/forms/barcode-scanner"

export default function Form({ isEditing = false, data, errors, onSubmit }) {
    const _ = deepdash(lodash)

    const { configuration } = usePage()

    const { data: productModelSelection, isLoading: isLoadingProductModelSelection } = useProductModelRepositories({
        paginate: false,
        order_by: "name",
        direction: "asc"
    })

    const id = data?.id

    // Bundle
    const [isBundle, setIsBundle] = useState(data?.is_bundle ?? false)
    const [bundle, setBundle] = useState(data?.bundles ?? [])

    // Pre-order
    const [isPreOrder, setIsPreOrder] = useState(data?.pre_order ?? false)

    // Main
    const [name, setName] = useState(data?.name ?? null)
    const [longName, setLongName] = useState(data?.long_name ?? null)
    const [sku, setSku] = useState(data?.sku ?? null)
    const [image, setImage] = useState(null)
    const [productModel, setProductModel] = useState(data?.product_model?.id ?? null)

    const [productModelObject, setProductModelObject] = useState()

    // Map
    const [price, setPrice] = useState(data?.prices ?? [])
    const [discount, setDiscount] = useState(data?.discount_pivots ?? [])
    const [reward, setReward] = useState(data?.reward_pivots ?? [])
    const [attribute, setAttribute] = useState(data?.attribute_data_pivots ?? [])
    const [stock, setStock] = useState(data?.stocks ?? [])

    useEffect(() => {
        if (data) {
            // Bundle
            setIsBundle(data?.is_bundle ?? false)
            setBundle(data?.bundles ?? [])

            // Pre-order
            setIsPreOrder(data?.pre_order ?? false)

            // Main
            setName(data?.name ?? null)
            setLongName(data?.long_name ?? null)
            setSku(data?.sku ?? null)
            setProductModel(data?.product_model?.id ?? null)

            // Map
            setPrice(data?.prices ?? [])
            setDiscount(data?.discount_pivots ?? [])
            setReward(data?.reward_pivots ?? [])
            setAttribute(data?.attribute_data_pivots ?? [])
            setStock(data?.stocks ?? [])
        }
    }, [data])

    useEffect(() => {
        if (isBundle && !data) {
            var attributeData = []

            bundle.forEach((data) => {
                data.attribute_data_pivots.forEach((row) => {
                    attributeData.push({ attribute: row.attribute, attribute_id: row.attribute.id, attribute_group: row.attribute_group, attribute_group_id: row.attribute_group_id })
                })
            })

            setAttribute(attributeData)
        }
    }, [bundle])

    useEffect(() => {
        if (isBundle) {
            setSku(null)
            setStock([])
        } else {
            setBundle([])
        }
    }, [isBundle])

    useEffect(() => {
        if (productModel && !isLoadingProductModelSelection) {
            setProductModelObject(productModelSelection.find((row) => row.id === productModel))
        }
    }, [productModel, isLoadingProductModelSelection])

    useEffect(() => {
        if (!isEditing) {
            setLongName(name)
        }
    }, [name])

    const handleAddPrice = (data) => {
        setPrice([...price, data])
    }

    const handleRemovePrice = (index) => {
        const value = price.filter((row, i) => i !== index)

        setPrice(value)
    }

    const handleAddDiscount = (data) => {
        setDiscount([...discount, data])
    }

    const handleRemoveDiscount = (index) => {
        const value = discount.filter((row, i) => i !== index)

        setDiscount(value)
    }

    const handleAddReward = (data) => {
        setReward([...reward, data])
    }

    const handleRemoveReward = (index) => {
        const value = reward.filter((row, i) => i !== index)

        setReward(value)
    }

    const handleAddAttribute = (data) => {
        setAttribute([...attribute, data])
    }

    const handleRemoveAttribute = (index) => {
        const value = attribute.filter((row, i) => i !== index)

        setAttribute(value)
    }

    const handleAddStock = (data) => {
        setStock([...stock, data])
    }

    const handleRemoveStock = (index) => {
        const value = stock.filter((row, i) => i !== index)

        setStock(value)
    }

    const handleAddBundle = (data) => {
        setBundle([...bundle, data])
    }

    const handleRemoveBundle = (index) => {
        const value = bundle.filter((row, i) => i !== index)

        setBundle(value)
    }

    const handleSubmit = (e) => {
        e.preventDefault()

        const discountData = discount.map((row) => {
            row.discount_type_id = row.discount.id

            return row
        })

        const rewardData = reward.map((row) => {
            row.reward_type_id = row.reward.id

            return row
        })

        const bundleData = bundle.map((row) => {
            return {
                product_unit_id: row.id
            }
        })

        const stockData = stock.map((row) => {
            // Filter empty data
            var temp = Object.fromEntries(Object.entries(row).filter(([_, v]) => (v != null && v != '') || v === 0))

            return temp
        })

        if (!nProgress.isStarted()) {
            onSubmit({
                id,
                name,
                long_name: longName,
                sku,
                image,
                pre_order: isPreOrder ? "1" : "0",
                product_model_id: productModel,

                product_model: productModelObject,

                attribute,
                price,
                discount: discountData,
                reward: rewardData,
                stock: stockData,

                is_bundle: isBundle,
                bundle: bundleData
            })
        } else {
            toast.error("Previous action is loading")
        }
    }
    return (
        <form onSubmit={handleSubmit} className="mt-8 space-y-8">
            {/* <div className="mt-10 sm:mt-0">
                <div className="md:grid md:grid-cols-3 md:gap-6">
                    <div className="md:col-span-1">
                        <div className="px-4 sm:px-0">
                            <h3 className="text-lg font-medium leading-6 text-gray-900">Data Bundle</h3>
                            <p className="mt-1 text-sm text-gray-600">Tentukan apakah produk merupakan produk bundle atau tidak.</p>
                        </div>
                    </div>
                    <div className="mt-5 md:col-span-2 md:mt-0">
                        <div className="border sm:rounded-xl">
                            <div className="px-4 py-5 space-y-8 sm:p-6">
                                <fieldset>
                                    <legend className="sr-only">Bundle</legend>
                                    <div className="text-base font-medium text-gray-900" aria-hidden="true">
                                        Bundle
                                    </div>
                                    <div className="mt-4 space-y-4">
                                        <div className="flex items-start">
                                            <div className="flex items-center h-5">
                                                <Checkbox disabled={data} id="is_bundle" checked={isBundle} onChange={() => setIsBundle(!isBundle)} />
                                            </div>
                                            <div className="ml-3 text-sm">
                                                <label htmlFor="is_bundle" className="font-medium text-gray-700">
                                                    Is it bundle?
                                                </label>
                                                <p className="text-gray-500">Check if the product is bundle.</p>
                                            </div>
                                        </div>
                                    </div>
                                </fieldset>
                                {isBundle && (
                                    // Set min width to zero, fix fieldset overflowing parent.
                                    <fieldset className="min-w-0">
                                        <legend className="sr-only">Unit</legend>
                                        <div className="text-base font-medium text-gray-900" aria-hidden="true">
                                            Unit
                                        </div>
                                        <div className="mt-4 space-y-4">
                                            <div className="overflow-x-auto border border-neutral-200 rounded-xl">
                                                <table className="min-w-full overflow-x-auto divide-y divide-neutral-200">
                                                    <thead className="bg-neutral-50 rounded-t-3xl">
                                                        <tr>
                                                            <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">Product Unit</th>
                                                            <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">Product Model</th>
                                                            <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">SKU</th>
                                                            {true && (
                                                                <th scope="col" className="relative px-6 py-3"><span className="sr-only">{lang.action}</span></th>
                                                            )}
                                                        </tr>
                                                    </thead>
                                                    <tbody className="bg-white divide-y divide-neutral-200">
                                                        {bundle.length === 0 && (
                                                            <tr className="text-center">
                                                                <td colSpan="10" className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                                                    {lang.no_data}
                                                                </td>
                                                            </tr>
                                                        )}
                                                        {bundle.length !== 0 && bundle?.map((row, index) => (
                                                            <tr key={row}>
                                                                <td className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                                                    <div className="flex items-center">
                                                                        {row.image ? (
                                                                            <a href={row.image} target="_blank" className="w-10 h-10">
                                                                                <img className="object-cover rounded-full aspect-square" src={row.image} alt="Product Image" />
                                                                            </a>
                                                                        ) : (
                                                                            <div className="flex items-center justify-center w-10 h-10 rounded-full bg-neutral-100">
                                                                                <PhotoOff className="w-5 h-5" strokeWidth={1.5} />
                                                                            </div>
                                                                        )}
                                                                        <div className="ml-4 space-y-1">
                                                                            <div>{row.name}</div>
                                                                            <div className="font-medium text-neutral-900">{row.long_name}</div>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td class="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                                                    <div className="flex flex-col space-y-1">
                                                                        <div>{row.product_model?.name}</div>
                                                                        <div className="font-medium text-neutral-900">{row.product_model?.long_name}</div>
                                                                    </div>
                                                                </td>
                                                                <td className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                                                    {row.sku}
                                                                </td>
                                                                {true && (
                                                                    <td class="px-6 py-4 text-xs font-medium text-right whitespace-nowrap">
                                                                        <button type="button" onClick={() => handleRemoveBundle(index)} class={`${false ? 'opacity-50 bg-red-900' : 'bg-red-800'} inline-flex items-center p-1 text-white transition rounded-full active:hover:scale-90`}>
                                                                            <X className="w-6 h-6" />
                                                                        </button>
                                                                    </td>
                                                                )}
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div className="text-xs">
                                                <AddBundle data={bundle} onSubmit={(data) => handleAddBundle(data)} />
                                            </div>
                                        </div>
                                    </fieldset>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}

            {/* <div className="hidden sm:block" aria-hidden="true">
                <div className="py-5">
                    <div className="border-t border-gray-200" />
                </div>
            </div> */}

            <div className="mt-10 sm:mt-0">
                <div className="md:grid md:grid-cols-3 md:gap-6">
                    <div className="md:col-span-1">
                        <div className="px-4 sm:px-0">
                            <h3 className="text-lg font-medium leading-6 text-gray-900">{lang.main_form}</h3>
                            <p className="mt-1 text-sm text-gray-600">{lang.please_fill_the_provided_form_input}</p>
                        </div>
                    </div>
                    <div className="mt-5 md:col-span-2 md:mt-0">
                        <div className="border sm:rounded-xl">
                            <div className="p-4 sm:p-6">
                                <div className="grid gap-4 sm:grid-cols-2">
                                    <div className="col-span-2 sm:col-span-1">
                                        <Label htmlFor="image" value="Image" />
                                        <InputImage disabled={configuration('site') === 'go'} onChange={(value) => setImage(value)} aspect="square" id="image" name="image" src={data?.image} error={errors.image} alt="https://placehold.co/400x400/F5F5F5/404040?font=source-sans-pro&text=400x400" />
                                        <ErrorMessage error={errors.image} />
                                    </div>
                                    <div className="col-span-2 space-y-4 sm:col-span-1">
                                        <div>
                                            <Label htmlFor="model" value="Product Model" />
                                            <SelectSearchable disabled={configuration('site') === 'go'} filter={(value) => value.long_name} onChange={(value) => setProductModel(value)} selection={productModelSelection} isLoading={isLoadingProductModelSelection} value={productModel} keyValue={(value) => value.id} title={(value) => value.name} description={(value) => value.long_name} placeholder="Select Product Model" error={errors.product_model_id} />
                                            <ErrorMessage error={errors.product_model_id} />
                                        </div>
                                        {!isBundle && (
                                            <div>
                                                <Label htmlFor="sku" value="SKU" />
                                                <div className="flex space-x-2">
                                                    <Input disabled={configuration('site') === 'go'} placeholder="000-SKU-123" onChange={(e) => setSku(e.target.value)} value={sku} id="sku" error={errors.sku} />
                                                    <BarcodeScanner onScan={(value) => setSku(value)} />
                                                </div>
                                                <ErrorMessage error={errors.sku} />
                                            </div>
                                        )}
                                        <div>
                                            <Label htmlFor="name" value="Name" />
                                            <Input disabled={configuration('site') === 'go'} placeholder="Caliburn AK3 - White" onChange={(e) => setName(e.target.value)} value={name} id="name" error={errors.name} />
                                            <Description error={errors.name} />
                                        </div>
                                        <div>
                                            <Label htmlFor="long_name" value="Long Name" />
                                            <Input disabled={configuration('site') === 'go'} placeholder="Uwell Caliburn AK3 - White" onChange={(e) => setLongName(e.target.value)} value={longName} id="long_name" error={errors.long_name} />
                                            <Description error={errors.long_name} />
                                        </div>
                                    </div>
                                    {configuration('site') === 'hq' && (
                                        <div className="col-span-2">
                                            <div className="flex items-start">
                                                <div className="flex items-center h-5">
                                                    <Checkbox id="is_pre_order" checked={isPreOrder} onChange={() => setIsPreOrder(!isPreOrder)} />
                                                </div>
                                                <div className="ml-3 text-sm">
                                                    <label htmlFor="is_pre_order" className="font-medium text-gray-700">
                                                        Pre-order
                                                    </label>
                                                    <p className="text-gray-500">Check if the product is pre-order.</p>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="hidden sm:block" aria-hidden="true">
                <div className="py-5">
                    <div className="border-t border-gray-200" />
                </div>
            </div>

            <div className="mt-10 sm:mt-0">
                <div className="md:grid md:grid-cols-3 md:gap-6">
                    <div className="md:col-span-1">
                        <div className="px-4 sm:px-0">
                            <h3 className="text-lg font-medium leading-6 text-gray-900">Data Atribut</h3>
                            <p className="mt-1 text-sm text-gray-600">Data atribut produk seperti warna, rasa.</p>
                        </div>
                    </div>
                    <div className="mt-5 md:col-span-2 md:mt-0">
                        <div className="border sm:rounded-xl">
                            <div className="px-4 py-5 space-y-4 sm:p-6">
                                <div className="overflow-x-auto border border-neutral-200 rounded-xl">
                                    <table className="min-w-full overflow-x-auto divide-y divide-neutral-200">
                                        <thead className="bg-neutral-50 rounded-t-3xl">
                                            <tr>
                                                <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">Group Atribut</th>
                                                <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">Atribut</th>
                                                {true && (
                                                    <th scope="col" className="relative px-6 py-3"><span className="sr-only">{lang.action}</span></th>
                                                )}
                                            </tr>
                                        </thead>
                                        <tbody className="bg-white divide-y divide-neutral-200">
                                            {attribute.length === 0 && (
                                                <tr className="text-center">
                                                    <td colSpan="10" className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                                        {lang.no_data}
                                                    </td>
                                                </tr>
                                            )}
                                            {attribute.length !== 0 && attribute?.map((row, index) => (
                                                <tr key={row}>
                                                    <td className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                                        {row.attribute_group?.name}
                                                    </td>
                                                    <td className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                                        {row.attribute?.name}
                                                    </td>
                                                    {configuration('site') === 'hq' && (
                                                        <td class="px-6 py-4 text-xs font-medium text-right whitespace-nowrap">
                                                            <button type="button" onClick={() => handleRemoveAttribute(index)} class={`${false ? 'opacity-50 bg-red-900' : 'bg-red-800'} inline-flex items-center p-1 text-white transition rounded-full active:hover:scale-90`}>
                                                                <X className="w-6 h-6" />
                                                            </button>
                                                        </td>
                                                    )}
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                                {configuration('site') === 'hq' && (
                                    <div className="text-xs">
                                        <AddAttribute data={attribute} onSubmit={(data) => handleAddAttribute(data)} />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {!isBundle && (
                <>
                    <div className="hidden sm:block" aria-hidden="true">
                        <div className="py-5">
                            <div className="border-t border-gray-200" />
                        </div>
                    </div>

                    <div className="mt-10 sm:mt-0">
                        <div className="md:grid md:grid-cols-3 md:gap-6">
                            <div className="md:col-span-1">
                                <div className="px-4 sm:px-0">
                                    <h3 className="text-lg font-medium leading-6 text-gray-900">Data Stock</h3>
                                    <p className="mt-1 text-sm text-gray-600">List data stock produk.</p>
                                </div>
                            </div>
                            <div className="mt-5 md:col-span-2 md:mt-0">
                                <div className="border sm:rounded-xl">
                                    <div className="px-4 py-5 space-y-4 sm:p-6">
                                        <div className={`${matchObjectRegex(errors, 'stock.*.purchase_order_id') ? 'border-red-200' : 'border-neutral-200'} overflow-x-auto border rounded-xl`}>
                                            <table className="min-w-full overflow-x-auto divide-y divide-neutral-200">
                                                <thead className="bg-neutral-50 rounded-t-3xl">
                                                    <tr>
                                                        <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">Purchase Date</th>
                                                        <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">DoS Date</th>
                                                        <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">Outlet ID</th>
                                                        <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">Purchase Order ID</th>
                                                        <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">Purchase Stock</th>
                                                        <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">Current Stock</th>
                                                        <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">Amount</th>
                                                        <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">HPP</th>
                                                        {configuration('site') === 'go' && (
                                                            <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">Maximum Retail Price</th>
                                                        )}
                                                        {configuration('site') === 'hq' && (
                                                            <th scope="col" className="relative px-6 py-3"><span className="sr-only">{lang.action}</span></th>
                                                        )}
                                                    </tr>
                                                </thead>
                                                <tbody className="bg-white divide-y divide-neutral-200">
                                                    {stock.length === 0 && (
                                                        <tr className="text-center">
                                                            <td colSpan="10" className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                                                {lang.no_data}
                                                            </td>
                                                        </tr>
                                                    )}
                                                    {stock.length !== 0 && stock?.map((row, index) => (
                                                        <tr key={row}>
                                                            <td className="px-6 py-4 text-xs font-medium text-neutral-900 whitespace-nowrap">
                                                                {moment(row.purchase_date).format('MMMM D, YYYY')}
                                                            </td>
                                                            <td className="px-6 py-4 text-xs font-medium text-neutral-900 whitespace-nowrap">
                                                                {row.dos_date ? moment(row.dos_date).format('MMMM D, YYYY') : '-'}
                                                            </td>
                                                            <td className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                                                {row.outlet_id ?? '-'}
                                                            </td>
                                                            <td className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                                                <div className="inline-flex items-center space-x-2">
                                                                    <span>{row.purchase_order_id ?? '-'}</span>
                                                                    <ErrorTooltip error={errors[`stock.${index}.purchase_order_id`]} />
                                                                </div>
                                                            </td>
                                                            <td className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                                                {row.purchase_stock}
                                                            </td>
                                                            <td className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                                                {row.current_stock}
                                                            </td>
                                                            <td className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                                                {Intl.NumberFormat('id-Id', { style: 'currency', currency: "IDR" }).format(row.amount)}
                                                            </td>
                                                            <td className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                                                {Intl.NumberFormat('id-Id', { style: 'currency', currency: "IDR" }).format(row.hpp_amount)}
                                                            </td>
                                                            {configuration('site') === 'go' && (
                                                                <td className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                                                    {Intl.NumberFormat('id-Id', { style: 'currency', currency: "IDR" }).format(row.maximum_retail_price)}
                                                                </td>
                                                            )}
                                                            {configuration('site') === 'hq' && (
                                                                <td class="px-6 py-4 text-xs font-medium text-right whitespace-nowrap">
                                                                    <button type="button" onClick={() => handleRemoveStock(index)} class={`${false ? 'opacity-50 bg-red-900' : 'bg-red-800'} inline-flex items-center p-1 text-white transition rounded-full active:hover:scale-90`}>
                                                                        <X className="w-6 h-6" />
                                                                    </button>
                                                                </td>
                                                            )}
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                        {configuration('site') === 'hq' && (
                                            <div className="text-xs">
                                                <AddStock onSubmit={(data) => handleAddStock(data)} />
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <ErrorMessage error={Object.keys(errors).filter((row) => row.includes("purchase_order_id")).reduce((object, key) => errors[key], [])} />
                            </div>
                        </div>
                    </div>
                </>
            )}

            <div className="hidden sm:block" aria-hidden="true">
                <div className="py-5">
                    <div className="border-t border-gray-200" />
                </div>
            </div>

            <div className="mt-10 sm:mt-0">
                <div className="md:grid md:grid-cols-3 md:gap-6">
                    <div className="md:col-span-1">
                        <div className="px-4 sm:px-0">
                            <h3 className="text-lg font-medium leading-6 text-gray-900">Data Harga</h3>
                            <p className="mt-1 text-sm text-gray-600">List data harga produk.</p>
                        </div>
                    </div>
                    <div className="mt-5 md:col-span-2 md:mt-0">
                        <div className="border sm:rounded-xl">
                            <div className="px-4 py-5 space-y-4 sm:p-6">
                                <div className="overflow-x-auto border border-neutral-200 rounded-xl">
                                    <table className="min-w-full overflow-x-auto divide-y divide-neutral-200">
                                        <thead className="bg-neutral-50 rounded-t-3xl">
                                            <tr>
                                                <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">Active Date</th>
                                                <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">Maximum Retail Price</th>
                                                <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">Price</th>
                                                {true && (
                                                    <th scope="col" className="relative px-6 py-3"><span className="sr-only">{lang.action}</span></th>
                                                )}
                                            </tr>
                                        </thead>
                                        <tbody className="bg-white divide-y divide-neutral-200">
                                            {price.length === 0 && (
                                                <tr className="text-center">
                                                    <td colSpan="10" className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                                        {lang.no_data}
                                                    </td>
                                                </tr>
                                            )}
                                            {price.length !== 0 && price?.map((row, index) => (
                                                <tr key={row}>
                                                    <td className="px-6 py-4 text-xs font-medium text-neutral-900 whitespace-nowrap">
                                                        {moment(row.active_date).format('MMMM D, YYYY')}
                                                    </td>
                                                    <td className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                                        {Intl.NumberFormat('id-Id', { style: 'currency', currency: "IDR" }).format(row.maximum_retail_price)}
                                                    </td>
                                                    <td className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                                        {Intl.NumberFormat('id-Id', { style: 'currency', currency: "IDR" }).format(row.price)}
                                                    </td>
                                                    {true && (
                                                        <td class="px-6 py-4 text-xs font-medium text-right whitespace-nowrap">
                                                            <button type="button" onClick={() => handleRemovePrice(index)} class={`${false ? 'opacity-50 bg-red-900' : 'bg-red-800'} inline-flex items-center p-1 text-white transition rounded-full active:hover:scale-90`}>
                                                                <X className="w-6 h-6" />
                                                            </button>
                                                        </td>
                                                    )}
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="text-xs">
                                    <AddPrice priceData={price} stockData={stock} onSubmit={(data) => handleAddPrice(data)} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="hidden sm:block" aria-hidden="true">
                <div className="py-5">
                    <div className="border-t border-gray-200" />
                </div>
            </div>

            <div className="mt-10 sm:mt-0">
                <div className="md:grid md:grid-cols-3 md:gap-6">
                    <div className="md:col-span-1">
                        <div className="px-4 sm:px-0">
                            <h3 className="text-lg font-medium leading-6 text-gray-900">Data Diskon</h3>
                            <p className="mt-1 text-sm text-gray-600">List data diskon produk.</p>
                        </div>
                    </div>
                    <div className="mt-5 md:col-span-2 md:mt-0">
                        <div className="border sm:rounded-xl">
                            <div className="px-4 py-5 space-y-4 sm:p-6">
                                <div className="overflow-x-auto border border-neutral-200 rounded-xl">
                                    <table className="min-w-full overflow-x-auto divide-y divide-neutral-200">
                                        <thead className="bg-neutral-50 rounded-t-3xl">
                                            <tr>
                                                <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">Discount</th>
                                                <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">From</th>
                                                <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">To</th>
                                                {true && (
                                                    <th scope="col" className="relative px-6 py-3"><span className="sr-only">{lang.action}</span></th>
                                                )}
                                            </tr>
                                        </thead>
                                        <tbody className="bg-white divide-y divide-neutral-200">
                                            {discount.length === 0 && (
                                                <tr className="text-center">
                                                    <td colSpan="10" className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                                        {lang.no_data}
                                                    </td>
                                                </tr>
                                            )}
                                            {discount.length !== 0 && discount?.map((row, index) => (
                                                <tr key={row}>
                                                    <td className="px-6 py-4 text-xs font-medium text-neutral-900 whitespace-nowrap">
                                                        {row.discount?.name}
                                                    </td>
                                                    <td className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                                        {moment(row.from_date).format('MMMM D, YYYY')}
                                                    </td>
                                                    <td className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                                        {moment(row.to_date).format('MMMM D, YYYY')}
                                                    </td>
                                                    {true && (
                                                        <td class="px-6 py-4 text-xs font-medium text-right whitespace-nowrap">
                                                            <button type="button" onClick={() => handleRemoveDiscount(index)} class={`${false ? 'opacity-50 bg-red-900' : 'bg-red-800'} inline-flex items-center p-1 text-white transition rounded-full active:hover:scale-90`}>
                                                                <X className="w-6 h-6" />
                                                            </button>
                                                        </td>
                                                    )}
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="text-xs">
                                    <AddDiscount data={discount} onSubmit={(data) => handleAddDiscount(data)} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="hidden sm:block" aria-hidden="true">
                <div className="py-5">
                    <div className="border-t border-gray-200" />
                </div>
            </div>

            <div className="mt-10 sm:mt-0">
                <div className="md:grid md:grid-cols-3 md:gap-6">
                    <div className="md:col-span-1">
                        <div className="px-4 sm:px-0">
                            <h3 className="text-lg font-medium leading-6 text-gray-900">Data Reward</h3>
                            <p className="mt-1 text-sm text-gray-600">List data reward produk.</p>
                        </div>
                    </div>
                    <div className="mt-5 md:col-span-2 md:mt-0">
                        <div className="border sm:rounded-xl">
                            <div className="px-4 py-5 space-y-4 sm:p-6">
                                <div className="overflow-x-auto border border-neutral-200 rounded-xl">
                                    <table className="min-w-full overflow-x-auto divide-y divide-neutral-200">
                                        <thead className="bg-neutral-50 rounded-t-3xl">
                                            <tr>
                                                <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">Reward</th>
                                                <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">From</th>
                                                <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">To</th>
                                                {true && (
                                                    <th scope="col" className="relative px-6 py-3"><span className="sr-only">{lang.action}</span></th>
                                                )}
                                            </tr>
                                        </thead>
                                        <tbody className="bg-white divide-y divide-neutral-200">
                                            {reward.length === 0 && (
                                                <tr className="text-center">
                                                    <td colSpan="10" className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                                        {lang.no_data}
                                                    </td>
                                                </tr>
                                            )}
                                            {reward.length !== 0 && reward?.map((row, index) => (
                                                <tr key={row}>
                                                    <td className="px-6 py-4 text-xs font-medium text-neutral-900 whitespace-nowrap">
                                                        {row.reward?.name}
                                                    </td>
                                                    <td className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                                        {moment(row.from_date).format('MMMM D, YYYY')}
                                                    </td>
                                                    <td className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                                        {moment(row.to_date).format('MMMM D, YYYY')}
                                                    </td>
                                                    {true && (
                                                        <td class="px-6 py-4 text-xs font-medium text-right whitespace-nowrap">
                                                            <button type="button" onClick={() => handleRemoveReward(index)} class={`${false ? 'opacity-50 bg-red-900' : 'bg-red-800'} inline-flex items-center p-1 text-white transition rounded-full active:hover:scale-90`}>
                                                                <X className="w-6 h-6" />
                                                            </button>
                                                        </td>
                                                    )}
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="text-xs">
                                    <AddReward data={reward} onSubmit={(data) => handleAddReward(data)} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="mt-8 text-xs text-right">
                <button type="submit" className="items-center px-6 py-3 text-white transition bg-neutral-800 rounded-xl active:hover:scale-90">
                    <span>Submit</span>
                </button>
            </div>
        </form>
    )
}
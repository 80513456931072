import { lang } from "config"
import { useAuth } from "contexts/auth-context"
import { useLocation } from "react-router-dom"
import BranchDropdown from "./branch-dropdown"
import UserDropdown from "./user-dropdown"
import { CloudOff } from "components/icons/outline"
import { useAuditLogContext } from "contexts/audit-log-context"

const Topbar = ({ isSidebarOpen, toggleSidebar }) => {
    const { isOnline } = useAuditLogContext()

    const { user } = useAuth()

    return (
        <div className="flex items-center justify-between w-full px-4 lg:px-8 py-4 border-b-[1.5px] border-neutral-200">
            <div className="hidden text-sm lg:block">{lang.hello} {user ? user?.name : "there"}!</div>
            <button onClick={toggleSidebar} className="flex items-center justify-center border lg:hidden rounded-xl w-9 h-9 bg-neutral-100">
                <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" width={24} height={24} viewBox="0 0 24 24" stroke-width={1.5} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <path d="M4 4m0 2a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2z"></path>
                    <path d="M9 4v16"></path>
                    <path d="M14 10l2 2l-2 2"></path>
                </svg>
            </button>
            <div className="flex items-center space-x-4">
                {!isOnline && (
                    <CloudOff className="w-5 h-5 text-red-500" strokeWidth={1.5} />
                )}
                <BranchDropdown />
                <UserDropdown />
            </div>
        </div>
    )
}

export default Topbar
import { Fragment, useState } from 'react'
import { Combobox, Listbox, Transition } from '@headlessui/react'
import { Check, ChevronUpDown } from 'components/icons/outline'
import { useEffect } from 'react'

const SelectSearchable = ({ filter = () => { }, selection = [], isLoading, value, placeholder, keyValue = () => { }, title = () => { }, description = () => { }, onChange = () => { }, disabled = false, error, reverse = false, multiple = false }) => {
    const [selected, setSelected] = useState(value ? (selection.find((row) => keyValue(row) == value) ?? {}) : {})
    const [query, setQuery] = useState('')

    const [filtered, setFiltered] = useState(query === undefined || query === '' ? selection : selection.filter((row) => filter(row)?.toLowerCase().includes(query?.toLowerCase())))

    useEffect(() => {
        onChange(keyValue(selected))
    }, [selected])

    useEffect(() => {
        if (value && selection.length !== 0 && !isLoading) {
            setSelected(selection.find((row) => keyValue(row) == value) ?? {})
        }
    }, [isLoading, value])

    useEffect(() => {
        setFiltered(query === undefined || query === '' ? selection : selection.filter((row) => filter(row)?.toLowerCase().includes(query?.toLowerCase())))
    }, [query, selection])

    return (
        <Combobox value={selected} onChange={setSelected} disabled={disabled} multiple={multiple}>
            <div className="relative mt-1">
                <div className={`${error ? 'border-red-200' : 'border-neutral-200'} ${!isLoading && title(selected) ? "" : "text-neutral-500"} ${disabled ? "bg-neutral-50 opacity-75" : ""} w-full text-sm text-left transition border focus:outline-none rounded-xl hover:border-neutral-400 hover:ring-inset focus:border-neutral-400 focus:ring focus:ring-neutral-200`}>
                    <Combobox.Input className="w-full text-sm border-none focus:ring-0 bg-none rounded-xl" placeholder={placeholder} displayValue={(data) => title(data)} onChange={(e) => setQuery(e.target.value)} />
                    <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                        <ChevronUpDown
                            className="w-5 h-5 text-neutral-400"
                            aria-hidden="true"
                        />
                    </Combobox.Button>
                </div>
                <Transition
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                    afterLeave={() => setQuery('')}>
                    <Combobox.Options className="absolute z-10 w-full p-1 mt-1 overflow-auto text-base bg-white border shadow-lg rounded-xl max-h-60 border-neutral-200 focus:outline-none sm:text-sm">
                        {filtered.length ? filtered?.map((row, index) => (
                            <Combobox.Option
                                key={keyValue(row)}
                                className={({ active }) => `relative cursor-pointer select-none py-2 px-2 rounded-lg ${active ? 'bg-neutral-100 text-neutral-900' : 'text-neutral-900'} transition`}
                                value={row}>
                                {({ selected }) => (
                                    <>
                                        <div className={`${reverse ? "flex-col-reverse" : "flex-col"} flex pr-6`}>
                                            <span className={`block truncate`}>
                                                {title(row)}
                                            </span>
                                            <span className={`block truncate text-xs text-neutral-600`}>
                                                {description(row)}
                                            </span>
                                        </div>
                                        <span className="absolute inset-y-0 right-0 flex items-center pr-2">
                                            <div className={`${selected ? "bg-neutral-600" : "border border-neutral-300"} w-5 h-5 flex items-center justify-center text-white rounded-full`}>
                                                {selected && (<Check className="w-4 h-4" stroke={1.5} aria-hidden="true" />)}
                                            </div>
                                        </span>
                                    </>
                                )}
                            </Combobox.Option>
                        )) : (
                            <div className="relative px-4 py-2 text-xs transition rounded-lg cursor-pointer select-none text-neutral-500">
                                <hr className="border-neutral-200" />
                                <span className="absolute inset-0 flex justify-center"><span className="px-2 bg-white">No Selection</span></span>
                            </div>
                        )}
                    </Combobox.Options>
                </Transition>
            </div>
        </Combobox >
    )
}

export default SelectSearchable
import React, { useEffect, useRef, useState } from "react"

import lodash from "lodash"
import deepdash from "deepdash"
import { lang } from "config"
import Label from "components/forms/label"
import nProgress from "nprogress"
import toast from "react-hot-toast"

import useDiscountRepositories from "repositories/discount"
import moment from "moment"
import { PhotoOff, X } from "components/icons/outline"
import Description from "components/forms/description"

import { Add as AddProductUnit } from "./product-unit"
import { useNavigate } from "react-router-dom"
import { useAuth } from "contexts/auth-context"
import axios from "axios"
import { useSWRConfig } from "swr"
import { useAuditLogContext } from "contexts/audit-log-context"

import Barcode from "react-barcode"
import { useReactToPrint } from "react-to-print"

const BarcodeList = React.forwardRef(({ data }, ref) => {
    return (
        <div ref={ref} className="hidden grid-cols-2 gap-4 p-4 print:grid">
            {data.map((row) => (
                <div className="flex items-center justify-center w-full">
                    <Barcode value={row.sku} />
                </div>
            ))}
        </div>
    )
})

export default function Form() {
    const _ = deepdash(lodash)

    const { user } = useAuth()
    const { cache } = useSWRConfig()

    const { queueAuditLog } = useAuditLogContext()

    const navigate = useNavigate()

    const barcodeRef = useRef()

    // Forms
    const [productUnit, setProductUnit] = useState([])
    const [level, setLevel] = useState()
    const [discount, setDiscount] = useState()
    const [from, setFrom] = useState()
    const [to, setTo] = useState()

    const [discountObject, setDiscountObject] = useState()

    const [errors, setErrors] = useState({})

    const levelSelection = [
        {
            key: "type",
            label: "Product Type"
        },
        {
            key: "brand",
            label: "Product Brand"
        },
        {
            key: "series",
            label: "Product Series"
        },
        {
            key: "model",
            label: "Product Model"
        },
        {
            key: "unit",
            label: "Product Unit"
        }
    ]

    const { data: discountSelection, isLoading: isLoadingDiscountSelection } = useDiscountRepositories({
        paginate: false
    })

    useEffect(() => {
        setProductUnit([])
    }, [level])

    useEffect(() => {
        if (discount && !isLoadingDiscountSelection) {
            setDiscountObject(discountSelection.find((row) => row.id === discount))
        }
    }, [discount, isLoadingDiscountSelection])

    const handleAddProductUnit = (data) => {
        setProductUnit([...productUnit, data])
    }

    const handleRemoveProductUnit = (index) => {
        const value = productUnit.filter((row, i) => i !== index)

        setProductUnit(value)
    }

    const handlePrint = useReactToPrint({
        documentTitle: "Print This Document",
        onBeforePrint: () => console.log("before printing..."),
        onAfterPrint: () => console.log("after printing..."),
        removeAfterPrint: true,
    })

    const handleSubmit = (e) => {
        e.preventDefault()

        return handlePrint(null, () => barcodeRef.current)
    }

    return (
        <>
            <form onSubmit={handleSubmit} className="mt-8 space-y-8 print:hidden">
                <div className="mt-10 sm:mt-0">
                    <div className="md:grid md:grid-cols-3 md:gap-6">
                        <div className="md:col-span-1">
                            <div className="px-4 sm:px-0">
                                <h3 className="text-lg font-medium leading-6 text-gray-900">{lang.main_form}</h3>
                                <p className="mt-1 text-sm text-gray-600">{lang.please_fill_the_provided_form_input}</p>
                            </div>
                        </div>
                        <div className="mt-5 md:col-span-2 md:mt-0">
                            <div className="border sm:rounded-xl">
                                <div className="px-4 py-5 space-y-8 sm:p-6">
                                    {/* Set min width to zero, fix fieldset overflowing parent. */}
                                    <fieldset className="min-w-0">
                                        <legend className="sr-only">Product Unit</legend>
                                        <div className="space-y-4">
                                            <div>
                                                <Label value="Product Unit" />
                                                <div className="mt-1 overflow-x-auto border border-neutral-200 rounded-xl">
                                                    <table className="min-w-full overflow-x-auto divide-y divide-neutral-200">
                                                        <thead className="bg-neutral-50 rounded-t-3xl">
                                                            <tr>
                                                                <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">Product Unit</th>
                                                                <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">SKU</th>
                                                                {true && (
                                                                    <th scope="col" className="relative px-6 py-3"><span className="sr-only">{lang.action}</span></th>
                                                                )}
                                                            </tr>
                                                        </thead>
                                                        <tbody className="bg-white divide-y divide-neutral-200">
                                                            {productUnit.length === 0 && (
                                                                <tr className="text-center">
                                                                    <td colSpan="10" className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                                                        {lang.no_data}
                                                                    </td>
                                                                </tr>
                                                            )}
                                                            {productUnit.length !== 0 && productUnit?.map((row, index) => (
                                                                <tr key={row}>
                                                                    <td className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                                                        <div className="flex items-center">
                                                                            {row.image ? (
                                                                                <a href={row.image} target="_blank" className="w-10 h-10">
                                                                                    <img className="object-cover rounded-full aspect-square" src={row.image} alt="Product Image" />
                                                                                </a>
                                                                            ) : (
                                                                                <div className="flex items-center justify-center w-10 h-10 rounded-full bg-neutral-100">
                                                                                    <PhotoOff className="w-5 h-5" strokeWidth={1.5} />
                                                                                </div>
                                                                            )}
                                                                            <div className="ml-4 space-y-1">
                                                                                <div>{row.name}</div>
                                                                                <div className="font-medium text-neutral-900">{row.long_name}</div>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                    <td className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                                                        {row.sku}
                                                                    </td>
                                                                    {true && (
                                                                        <td class="px-6 py-4 text-xs font-medium text-right whitespace-nowrap">
                                                                            <button type="button" onClick={() => handleRemoveProductUnit(index)} class={`${false ? 'opacity-50 bg-red-900' : 'bg-red-800'} inline-flex items-center p-1 text-white transition rounded-full active:hover:scale-90`}>
                                                                                <X className="w-6 h-6" />
                                                                            </button>
                                                                        </td>
                                                                    )}
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            <Description error={errors.product_units} />
                                            <div className="text-xs">
                                                <AddProductUnit data={{ level, product_unit: productUnit }} onSubmit={(data) => handleAddProductUnit(data)} />
                                            </div>
                                        </div>
                                    </fieldset>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="mt-8 text-xs text-right">
                    <button type="submit" className="items-center px-6 py-3 text-white transition bg-neutral-800 rounded-xl active:hover:scale-90">
                        <span>Print</span>
                    </button>
                </div>
            </form>
            <BarcodeList ref={barcodeRef} data={productUnit} />
        </>
    )
}
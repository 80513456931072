import axios from "axios"
import { useState, useContext, createContext, useEffect } from "react"
import useSWR from "swr"

// Initial state
const initialState = {
    isOnline: false,
    queueAuditLog: (url, method, event, actor, metadata, description, createdAt, status) => { }
}

// Audit log context
export const AuditLogContext = createContext(initialState)

// Pending log fetcher
const fetcher = async () => {
    var data = await JSON.parse(localStorage.getItem("pending-log")) ?? []

    return data
}

// Provider
export const AuditLogProvider = ({ children }) => {
    // Online status
    const [isOnline, setIsOnline] = useState(() => navigator.onLine)

    // Online status listener
    useEffect(() => {
        window.addEventListener('online', () => setIsOnline(true))
        window.addEventListener('offline', () => setIsOnline(false))

        return () => {
            window.removeEventListener('online', () => setIsOnline(true))
            window.removeEventListener('offline', () => setIsOnline(false))
        }
    })

    // Updating queued audit log data (swr (cached))
    const { data: updatingQueuedAuditLogData, mutate: mutateUpdatingQueuedAuditLogData } = useSWR("pending-log", fetcher, {
        refreshWhenHidden: true,
        refreshInterval: 1000
    })

    // Queued audit log data (state)
    const [queuedAuditLogData, setQueuedAuditLogData] = useState(updatingQueuedAuditLogData ?? [])

    // Cached (updating) queued audit log data listener
    useEffect(() => {
        if (updatingQueuedAuditLogData) {
            setQueuedAuditLogData(updatingQueuedAuditLogData)
        }
    }, [updatingQueuedAuditLogData])

    // Queued audit log data listener; Queued data processor
    useEffect(() => {
        if (queuedAuditLogData.length && isOnline) {
            var dispatchedData = []

            queuedAuditLogData.map((row) => {
                axios.post(`${process.env.REACT_APP_AUDIT_TRAIL_API_URL}/audit-log`, row.payload)
                    .then((response) => {
                        dispatchedData.push(row)
                    })
                    .catch((error) => {
                        var updatedData = queuedAuditLogData.map((value) => {
                            if (value.uuid === row.uuid) {
                                value.tries++
                            }
                        })

                        localStorage.setItem("pending-log", JSON.stringify(updatedData))
                    })
            })

            var filteredData = dispatchedData.filter((row) => !dispatchedData.find((value) => row.uuid === value.uuid))

            localStorage.setItem("pending-log", JSON.stringify(filteredData))
        }
    }, [queuedAuditLogData, isOnline])

    // Queue log function
    const queueAuditLog = (url, method, event, actor, metadata, description, createdAt, status) => {
        const payload = {
            url,
            method,
            event,
            actor,
            metadata: JSON.stringify(metadata),
            description,
            status,

            created_at: createdAt
        }

        const uuid = crypto.randomUUID()

        const queue = {
            uuid,
            payload,
            tries: 0
        }

        const pendingLog = localStorage.getItem("pending-log") ? JSON.parse(localStorage.getItem("pending-log")) : []

        localStorage.setItem("pending-log", JSON.stringify([...pendingLog, queue]))
    }

    const value = {
        isOnline,

        queueAuditLog
    }

    return (
        <AuditLogContext.Provider value={value}>
            {children}
        </AuditLogContext.Provider>
    )
}

export const useAuditLogContext = () => {
    return useContext(AuditLogContext)
}
import { useEffect, useState } from "react"
import { Link, useLoaderData, useLocation, useNavigate, useOutletContext } from "react-router-dom"

import axios from "axios"
import { useAuth } from "contexts/auth-context"
import { useLayoutEffect } from "react"
import Form from "./form"
import nProgress from "nprogress"
import toast from "react-hot-toast"
import { useSWRConfig } from "swr"
import { usePage } from "contexts/page-context"
import { Transition } from "components"
import moment from "moment"
import { useAuditLogContext } from "contexts/audit-log-context"

export default function Edit() {
    const { user } = useAuth()
    const { cache } = useSWRConfig()

    const { queueAuditLog } = useAuditLogContext()

    const { setTransition } = usePage()

    const { setTitle } = useOutletContext()

    const loaderData = useLoaderData()

    const location = useLocation()
    const navigate = useNavigate()

    const [prevRouteState, setPrevRouteState] = useState({})

    const [errors, setErrors] = useState({})

    useLayoutEffect(() => {
        const getPrevRouteState = async () => {
            setPrevRouteState({
                back: location.state?.back,
                from: location.state?.from,
                transition: location.state?.transition
            })
        }

        setTransition(location.state?.transition)
        setTitle(`Edit ${loaderData?.name}`)

        getPrevRouteState()
    }, [])

    const handleSubmit = (data) => {
        nProgress.start()

        toast.loading("Loading", {
            id: "submit"
        })

        const formData = new FormData()

        formData.append('_method', 'PUT')

        if (data.product_model_id)
            formData.append('product_model_id', data.product_model_id)

        if (data.name)
            formData.append('name', data.name)

        if (data.long_name)
            formData.append('long_name', data.long_name)

        if (data.sku)
            formData.append('sku', data.sku)

        if (data.image)
            formData.append('image', data.image)

        if (data.pre_order)
            formData.append('pre_order', data.pre_order)

        if (data.bundle.length) {
            for (let i = 0; i < data.bundle.length; i++) {
                for (let key of Object.keys(data.bundle[i])) {
                    formData.append(`bundle[${i}][${key}]`, data.bundle[i][key])
                }
            }
        } else {
            formData.append(`bundle`, '')
        }

        if (data.attribute.length) {
            for (let i = 0; i < data.attribute.length; i++) {
                for (let key of Object.keys(data.attribute[i])) {
                    formData.append(`attribute[${i}][${key}]`, data.attribute[i][key])
                }
            }
        } else {
            formData.append(`attribute`, '')
        }

        if (data.price.length) {
            for (let i = 0; i < data.price.length; i++) {
                for (let key of Object.keys(data.price[i])) {
                    formData.append(`price[${i}][${key}]`, data.price[i][key])
                }
            }
        } else {
            formData.append(`price`, '')
        }

        if (data.discount.length) {
            for (let i = 0; i < data.discount.length; i++) {
                for (let key of Object.keys(data.discount[i])) {
                    formData.append(`discount[${i}][${key}]`, data.discount[i][key])
                }
            }
        } else {
            formData.append(`discount`, '')
        }

        if (data.reward.length) {
            for (let i = 0; i < data.reward.length; i++) {
                for (let key of Object.keys(data.reward[i])) {
                    formData.append(`reward[${i}][${key}]`, data.reward[i][key])
                }
            }
        } else {
            formData.append(`reward`, '')
        }

        if (data.stock.length) {
            for (let i = 0; i < data.stock.length; i++) {
                for (let key of Object.keys(data.stock[i])) {
                    formData.append(`stock[${i}][${key}]`, data.stock[i][key])
                }
            }
        } else {
            formData.append(`stock`, '')
        }

        formData.append('updated_by', user?.name)

        const url = `${process.env.REACT_APP_INVENTORY_API_URL}/product/unit/${data?.id}`
        const oldData = {
            product_model_id: loaderData.product_model_id,
            product_model: loaderData.product_model?.name,
            name: loaderData.name,
            long_name: loaderData.long_name,
            sku: loaderData.sku,
            pre_order: loaderData.pre_order,
            // bundle: loaderData.bundles,
            attribute: loaderData.attribute_data_pivots.map((row) => {
                return {
                    attribute_group_id: row.attribute_group_id,
                    attribute_id: row.attribute_id,
                    attribute: row.attribute?.name,
                    attribute_group: row.attribute_group?.name,
                }
            }),
            price: loaderData.prices.map((row) => {
                return {
                    active_date: row.active_date,
                    maximum_retail_price: row.maximum_retail_price,
                    price: row.price
                }
            }),
            discount: loaderData.discount_pivots.map((row) => {
                return {
                    discount_type_id: row.discount_type_id,
                    from_date: row.from_date,
                    to_date: row.to_date,
                    discount: row.discount?.name
                }
            }),
            reward: loaderData.reward_pivots.map((row) => {
                return {
                    reward_type_id: row.reward_type_id,
                    from_date: row.from_date,
                    to_date: row.to_date,
                    reward: row.reward?.name
                }
            }),
            stock: loaderData.stocks.map((row) => {
                return {
                    outlet_id: row.outlet_id,
                    purchase_order_id: row.purchase_order_id,
                    purchase_date: row.purchase_date,
                    dos_date: row.dos_date,
                    amount: row.amount,
                    hpp_amount: row.hpp_amount,
                    maximum_retail_price: row.maximum_retail_price,
                    purchase_stock: row.purchase_stock,
                    current_stock: row.current_stock
                }
            }),
            updated_by: loaderData?.updated_by,
        }
        const payload = {
            product_model_id: data.product_model_id,
            product_model: data.product_model?.name,
            name: data.name,
            long_name: data.long_name,
            sku: data.sku,
            pre_order: data.pre_order,
            // bundle: data.bundle,
            attribute: data.attribute.map((row) => {
                return {
                    attribute_group_id: row.attribute_group_id,
                    attribute_id: row.attribute_id,
                    attribute: row.attribute?.name,
                    attribute_group: row.attribute_group?.name,
                }
            }),
            price: data.price.map((row) => {
                return {
                    active_date: row.active_date,
                    maximum_retail_price: row.maximum_retail_price,
                    price: row.price
                }
            }),
            discount: data.discount.map((row) => {
                return {
                    discount_type_id: row.discount_type_id,
                    from_date: row.from_date,
                    to_date: row.to_date,
                    discount: row.discount?.name
                }
            }),
            reward: data.reward.map((row) => {
                return {
                    reward_type_id: row.reward_type_id,
                    from_date: row.from_date,
                    to_date: row.to_date,
                    reward: row.reward?.name
                }
            }),
            stock: data.stock.map((row) => {
                return {
                    outlet_id: row.outlet_id,
                    purchase_order_id: row.purchase_order_id,
                    purchase_date: row.purchase_date,
                    dos_date: row.dos_date,
                    amount: row.amount,
                    hpp_amount: row.hpp_amount,
                    maximum_retail_price: row.maximum_retail_price,
                    purchase_stock: row.purchase_stock,
                    current_stock: row.current_stock
                }
            }),
            updated_by: user?.name,
        }

        axios.postForm(url, formData).then((response) => {
            // Queue audit log
            queueAuditLog(url, response.config.method.toUpperCase(), "Edit Product Unit Data", user?.name, { old: oldData, new: payload }, null, moment().format('YYYY-MM-DD HH:mm:ss'), response.status)

            // Clear SWR cache
            cache.clear()

            // Navigate page
            navigate({ pathname: '/product/unit' })

            // Show toast message
            toast.success(response.data.message, {
                id: "submit"
            })
        }).catch((error) => {
            // Assign validation message
            if (error.response.status === 422) {
                setErrors(error.response.data.errors)
            }

            // Show toast message
            toast.error(error.response.data.message, {
                ariaProps: {
                    superscript: error.response.status
                },
                id: "submit"
            })
        })
    }

    return (
        <Transition type="slide" reversed={true}>
            <Form isEditing data={loaderData} errors={errors} onSubmit={handleSubmit} />
        </Transition>
    )
}
import { useState, useEffect } from "react"

import ErrorMessage from "components/forms/error-message"
import lodash from "lodash"
import deepdash from "deepdash"
import { lang } from "config"
import Label from "components/forms/label"
import useProductBrandRepositories from "repositories/product-brand"
import useSeriesRepositories from "repositories/series"
import nProgress from "nprogress"
import toast from "react-hot-toast"
import SelectDescription from "components/forms/select-description"
import { usePage } from "contexts/page-context"
import SelectSearchable from "components/forms/select-searchable"

export default function Form({ data, errors, onSubmit }) {
    const _ = deepdash(lodash)

    const { configuration } = usePage()

    const id = data?.id

    const [productBrand, setProductBrand] = useState(data?.product_brand?.id ?? null)
    const [series, setSeries] = useState(data?.series?.id ?? null)

    const [productBrandObject, setProductBrandObject] = useState()
    const [seriesObject, setSeriesObject] = useState()

    const { data: productBrandSelection, isLoading: isLoadingProductBrandSelection } = useProductBrandRepositories({
        paginate: false,
        order_by: "brand.name",
        direction: "asc"
    })

    const { data: seriesSelection, isLoading: isLoadingSeriesSelection } = useSeriesRepositories({
        paginate: false,
        brand: productBrandSelection?.find((row) => row.id === productBrand)?.brand?.id,
        order_by: "name",
        direction: "asc"
    })

    useEffect(() => {
        if (data) {
            setProductBrand(data.product_brand.id ?? null)
            setSeries(data.series.id ?? null)
        } else {
            if (!configuration('hierarchy').find((row) => row.key === 'product-brand').value && configuration('hierarchy').find((row) => row.key === 'product-series').default_parent === 'auto' && !isLoadingProductBrandSelection) {
                setProductBrand(productBrandSelection[0]?.id)
            }
        }
    }, [data, isLoadingProductBrandSelection])

    useEffect(() => {
        if (productBrand && !isLoadingProductBrandSelection) {
            setProductBrandObject(productBrandSelection.find((row) => row.id === productBrand))
        }
    }, [productBrand, isLoadingProductBrandSelection])
    
    useEffect(() => {
        if (series && !isLoadingSeriesSelection) {
            setSeriesObject(seriesSelection.find((row) => row.id === series))
        }
    }, [series, isLoadingSeriesSelection])

    const handleSubmit = (e) => {
        e.preventDefault()

        if (!nProgress.isStarted()) {
            onSubmit({
                id,
                product_brand_id: productBrand,
                product_brand: productBrandObject,
                series_id: series,
                series: seriesObject
            })
        } else {
            toast.error("Previous action is loading")
        }
    }
    return (
        <form onSubmit={handleSubmit} className="mt-8 space-y-8">
            <div className="mt-10 sm:mt-0">
                <div className="md:grid md:grid-cols-3 md:gap-6">
                    <div className="md:col-span-1">
                        <div className="px-4 sm:px-0">
                            <h3 className="text-lg font-medium leading-6 text-gray-900">{lang.main_form}</h3>
                            <p className="mt-1 text-sm text-gray-600">{lang.please_fill_the_provided_form_input}</p>
                        </div>
                    </div>
                    <div className="mt-5 md:col-span-2 md:mt-0">
                        <div className="border sm:rounded-xl">
                            <div className="px-4 py-5 sm:p-6">
                                <div className="grid grid-cols-2 gap-4">
                                    {configuration('hierarchy').find((row) => row.key === 'product-brand').value && (
                                        <div>
                                            <Label htmlFor="product_brand" value="Product Brand" />
                                            <SelectSearchable filter={(value) => value.brand?.name} error={errors.product_brand_id} onChange={(value) => setProductBrand(value)} selection={productBrandSelection} isLoading={isLoadingProductBrandSelection} value={productBrand} keyValue={(value) => value.id} title={(value) => value.brand?.name} description={(value) => value.product_type?.name} placeholder="Select Product Brand" reverse />
                                            <ErrorMessage error={errors.product_brand_id} />
                                        </div>
                                    )}
                                    <div>
                                        <Label htmlFor="series" value="Series" />
                                        <SelectSearchable filter={(value) => value.name} error={errors.series_id} disabled={!productBrand || isLoadingSeriesSelection} onChange={(value) => setSeries(value)} selection={seriesSelection} isLoading={isLoadingSeriesSelection} value={series} keyValue={(value) => value.id} title={(value) => value.name} description={(value) => value.brand.name} placeholder="Select Series" reverse />
                                        <ErrorMessage error={errors.series_id} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="mt-8 text-xs text-right">
                <button type="submit" className="items-center px-6 py-3 text-white transition bg-neutral-800 rounded-xl active:hover:scale-90">
                    <span>{`${_.isEmpty(data) ? lang.create : lang.update}`}</span>
                </button>
            </div>
        </form>
    )
}
import { useState, useEffect } from "react"

import ErrorMessage from "components/forms/error-message"
import lodash from "lodash"
import deepdash from "deepdash"
import { lang } from "config"
import Label from "components/forms/label"
import Input from "components/forms/input"
import useAttributeGroupRepositories from "repositories/attribute-group"
import Description from "components/forms/description"
import nProgress from "nprogress"
import toast from "react-hot-toast"

export default function Form({ data, errors, onSubmit }) {
    const _ = deepdash(lodash)

    const { data: attributeGroupSelection, isLoading: isLoadingAttributeGroupSelection } = useAttributeGroupRepositories({
        paginate: false
    })

    const id = data?.id

    const [name, setName] = useState(data?.name ?? null)
    const [group, setGroup] = useState(data?.group?.id ?? null)

    const [groupObject, setGroupObject] = useState()

    useEffect(() => {
        if (group && !isLoadingAttributeGroupSelection) {
            setGroupObject(attributeGroupSelection.find((row) => row.id === group))
        }
    }, [group, isLoadingAttributeGroupSelection])

    const handleSubmit = (e) => {
        e.preventDefault()

        if (!nProgress.isStarted()) {
            onSubmit({
                id,
                name,
                attribute_group_id: group,
                attribute_group: groupObject
            })
        } else {
            toast.error("Previous action is loading")
        }
    }
    return (
        <form onSubmit={handleSubmit} className="mt-8 space-y-8">
            <div className="mt-10 sm:mt-0">
                <div className="md:grid md:grid-cols-3 md:gap-6">
                    <div className="md:col-span-1">
                        <div className="px-4 sm:px-0">
                            <h3 className="text-lg font-medium leading-6 text-gray-900">{lang.main_form}</h3>
                            <p className="mt-1 text-sm text-gray-600">{lang.please_fill_the_provided_form_input}</p>
                        </div>
                    </div>
                    <div className="mt-5 md:col-span-2 md:mt-0">
                        <div className="border sm:rounded-xl">
                            <div className="px-4 py-5 sm:p-6">
                                <div className="grid grid-cols-2 gap-4">
                                    <div>
                                        <Label htmlFor="group" value="Attribute Group" />
                                        <select onChange={(e) => setGroup(e.target.value)} value={group} name="group" id="group" className={`${errors.attribute_group_id ? 'border-red-200' : 'border-neutral-200'} block w-full px-2 py-2 mt-1 text-sm transition border focus:outline-none rounded-xl focus:border-neutral-400 focus:ring focus:ring-neutral-200`}>
                                            <option value={null} selected disabled>-- {lang.choose} Attribute Group --</option>
                                            {attributeGroupSelection?.length !== 0 && attributeGroupSelection?.map(row => (
                                                <option value={row.id}>{row.name}</option>
                                            ))}
                                        </select>
                                        <ErrorMessage error={errors.attribute_group_id} />
                                    </div>
                                    <div>
                                        <Label htmlFor="name" value={lang.name} />
                                        <Input placeholder="White" onChange={(e) => setName(e.target.value)} value={name} id="name" error={errors.name} />
                                        <Description error={errors.name} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="mt-8 text-xs text-right">
                <button type="submit" className="items-center px-6 py-3 text-white transition bg-neutral-800 rounded-xl active:hover:scale-90">
                    <span>{`${_.isEmpty(data) ? lang.create : lang.update}`}</span>
                </button>
            </div>
        </form>
    )
}
import { useState, useEffect } from "react"

import ErrorMessage from "components/forms/error-message"
import lodash from "lodash"
import deepdash from "deepdash"
import { lang } from "config"
import Label from "components/forms/label"
import Input from "components/forms/input"
import InputAmount from "components/forms/input-amount"
import Checkbox from "components/forms/checkbox"
import Description from "components/forms/description"
import nProgress from "nprogress"
import toast from "react-hot-toast"

export default function Form({ data, errors, onSubmit }) {
    const _ = deepdash(lodash)

    const id = data?.id

    const [name, setName] = useState(data?.name ?? null)
    const [sales, setSales] = useState(data?.sales_item_count ?? null)
    const [starting, setStarting] = useState(data?.starting_item_number ?? null)
    const [amount, setAmount] = useState(data?.amount ?? null)
    const [percentage, setPercentage] = useState(data?.percentage ?? null)
    const [multiply, setMultiply] = useState(data?.multiply ?? null)

    const handleSubmit = (e) => {
        e.preventDefault()

        if (!nProgress.isStarted()) {
            onSubmit({
                id,
                name,
                sales_item_count: sales,
                starting_item_number: starting,
                amount,
                percentage,
                multiply
            })
        } else {
            toast.error("Previous action is loading")
        }
    }
    return (
        <form onSubmit={handleSubmit} className="mt-8 space-y-8">
            <div className="mt-10 sm:mt-0">
                <div className="md:grid md:grid-cols-3 md:gap-6">
                    <div className="md:col-span-1">
                        <div className="px-4 sm:px-0">
                            <h3 className="text-lg font-medium leading-6 text-gray-900">{lang.main_form}</h3>
                            <p className="mt-1 text-sm text-gray-600">{lang.please_fill_the_provided_form_input}</p>
                        </div>
                    </div>
                    <div className="mt-5 md:col-span-2 md:mt-0">
                        <div className="border sm:rounded-xl">
                            <div className="px-4 py-5 sm:p-6">
                                <div className="grid grid-cols-2 gap-4">
                                    <div>
                                        <Label htmlFor="name" value="Name" />
                                        <Input placeholder="Weekly Reward" onChange={(e) => setName(e.target.value)} value={name} id="name" error={errors.name} />
                                        <Description error={errors.name} />
                                    </div>
                                    <div></div>
                                    <div>
                                        <Label htmlFor="sales" value="Sales Item Count" />
                                        <Input placeholder="0" type="number" onChange={(e) => setSales(e.target.value)} value={sales} id="sales" error={errors.sales_item_count} />
                                        <ErrorMessage error={errors.sales_item_count} />
                                    </div>
                                    <div>
                                        <Label htmlFor="starting" value="Starting Item Number" />
                                        <Input placeholder="0" type="number" onChange={(e) => setStarting(e.target.value)} value={starting} id="starting" error={errors.starting_item_number} />
                                        <ErrorMessage error={errors.starting_item_number} />
                                    </div>
                                    <div>
                                        <Label htmlFor="amount" value="Amount" />
                                        <InputAmount placeholder="0" onChange={(value) => setAmount(value)} value={parseFloat(amount)} id="amount" error={errors.amount} />
                                        <ErrorMessage error={errors.amount} />
                                    </div>
                                    <div>
                                        <Label htmlFor="percentage" value="Percentage" />
                                        <Input type="number" placeholder="0-100" onChange={(e) => setPercentage(e.target.value)} value={percentage} id="percentage" error={errors.percentage} />
                                        <ErrorMessage error={errors.percentage} />
                                    </div>
                                    <div>
                                        <Label htmlFor="multiply" value="Multiply" />
                                        <Checkbox onChange={() => setMultiply(!multiply)} value={true} checked={multiply} id="multiply" error={errors.multiply} />
                                        <ErrorMessage error={errors.multiply} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="mt-8 text-xs text-right">
                <button type="submit" className="items-center px-6 py-3 text-white transition bg-neutral-800 rounded-xl active:hover:scale-90">
                    <span>{`${_.isEmpty(data) ? lang.create : lang.update}`}</span>
                </button>
            </div>
        </form>
    )
}
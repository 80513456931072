import { useEffect, useState } from 'react'
import { Modal } from 'components'
import ErrorMessage from 'components/forms/error-message'
import { lang } from 'config'
import _ from 'lodash'
import { Plus } from 'components/icons/outline'
import axios from 'axios'
import { toast } from 'react-hot-toast'
import nProgress from 'nprogress'
import Label from 'components/forms/label'
import SelectDescription from 'components/forms/select-description'
import useSWR from 'swr'
import SelectSearchable from 'components/forms/select-searchable'

const fetcher = async (url, params) => {
    return await axios.get(url, { params }).then((res) => res.data.data)
}

const Content = ({ data, onSubmit }) => {
    const { data: targetSelection, isLoading: isLoadingTargetSelection } = useSWR([`${process.env.REACT_APP_INVENTORY_API_URL}/product/${data?.level}`, { paginate: false, order_by: data?.level === 'brand' ? "brand.name" : (data?.level === 'series' ? "series.name" : "name"), direction: "asc" }], fetcher)

    const [target, setTarget] = useState()
    const [targetId, setTargetId] = useState()

    const [errors, setErrors] = useState({})

    useEffect(() => {
        if (targetId && !isLoadingTargetSelection) {
            setTarget(targetSelection?.find((row) => row.id === targetId))
        }
    }, [targetId, isLoadingTargetSelection])

    const handleSubmit = (e) => {
        e.preventDefault()

        if (!nProgress.isStarted()) {
            nProgress.start()

            // toast.loading("Loading", {
            //     id: "submit"
            // })

            nProgress.done()

            onSubmit(target)

            // axios.post(`${process.env.REACT_APP_INVENTORY_API_URL}/discount/validate`, {
            //     type: 'dependency',
            //     target
            // }).then((response) => {
            //     toast.success(response.data.message, {
            //         id: "submit"
            //     })

            //     onSubmit({
            //         target
            //     })

            //     nProgress.done()
            // }).catch((error) => {
            //     if (error.response.status === 422) {
            //         setErrors(error.response.data.errors)
            //     }
            //     toast.error(error.response.data.message, {
            //         id: "submit"
            //     })

            //     console.log(error.response)

            //     nProgress.done()
            // })
        } else {
            toast.error("Previous action is loading")
        }
    }

    return (
        <div className="mt-8 space-y-8">
            <div className="space-y-4">
                <div className="grid grid-cols-2 gap-4">
                    <div className="col-span-2">
                        <Label htmlFor="target" value="Target" />
                        <SelectSearchable filter={(value) => data?.level === 'brand' ? value.brand?.name : (data?.level === 'series' ? value.series?.name : value.name)} disabled={!data?.level} onChange={(value) => setTargetId(value)} selection={targetSelection} isLoading={isLoadingTargetSelection} value={targetId} keyValue={(value) => value.id} title={(value) => data?.level === 'brand' ? value.brand?.name : (data?.level === 'series' ? value.series?.name : value.name)} description={(value) => data?.level === 'brand' ? value.product_type?.name : (data?.level === 'series' ? value.product_brand?.brand.name : value.long_name)} placeholder={`${data?.level ? 'Select Target' : 'Select Level First'}`} error={errors.target} reverse />
                        <ErrorMessage error={errors.target} />
                    </div>
                </div>
            </div>
            <div className="text-xs">
                <button onClick={handleSubmit} className="items-center px-6 py-3 text-white transition bg-neutral-800 rounded-xl active:hover:scale-90">
                    <span>{lang.save}</span>
                </button>
            </div>
        </div>
    )
}

const Add = ({ data, onSubmit }) => {
    const [isOpen, setIsOpen] = useState(false)

    const openModal = () => setIsOpen(true)
    const closeModal = () => setIsOpen(false)

    const handleSubmit = (data) => {
        onSubmit(data)

        closeModal()
    }

    return (
        <>
            <button type="button" onClick={openModal} className="inline-flex items-center px-4 py-3 space-x-2 transition border border-neutral-200 bg-neutral-50 rounded-xl active:hover:scale-90">
                <Plus className="w-4 h-4" stroke={1.5} />
                <span>{lang.create}</span>
            </button>
            <Modal isOpen={isOpen} onClose={closeModal} title={`${lang.create} ${lang.data}`} content={<Content data={data} onSubmit={handleSubmit} />} />
        </>
    )
}

export default Add
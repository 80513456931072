import { useEffect, useState } from "react"
import { Link, useLoaderData, useLocation, useNavigate, useOutletContext } from "react-router-dom"

import axios from "axios"
import { useAuth } from "contexts/auth-context"
import { useLayoutEffect } from "react"
import Form from "./form"
import nProgress from "nprogress"
import toast from "react-hot-toast"
import { useSWRConfig } from "swr"
import { usePage } from "contexts/page-context"
import { Transition } from "components"
import moment from "moment"
import { useAuditLogContext } from "contexts/audit-log-context"

export default function Edit() {
    const { user } = useAuth()
    const { cache } = useSWRConfig()

    const { queueAuditLog } = useAuditLogContext()

    const { setTransition } = usePage()

    const { setTitle } = useOutletContext()

    const loaderData = useLoaderData()

    const location = useLocation()
    const navigate = useNavigate()

    const [prevRouteState, setPrevRouteState] = useState({})

    const [errors, setErrors] = useState({})

    useLayoutEffect(() => {
        const getPrevRouteState = async () => {
            setPrevRouteState({
                back: location.state?.back,
                from: location.state?.from,
                transition: location.state?.transition
            })
        }

        setTransition(location.state?.transition)
        setTitle(`Edit ${loaderData?.name}`)

        getPrevRouteState()
    }, [])

    const handleSubmit = (data) => {
        nProgress.start()

        toast.loading("Loading", {
            id: "submit"
        })

        const url = `${process.env.REACT_APP_INVENTORY_API_URL}/product/type/${data?.id}`
        const oldData = {
            product_line_id: loaderData.product_line_id,
            product_line: loaderData.product_line?.name,
            name: loaderData.name,
            updated_by: loaderData?.updated_by
        }
        const payload = {
            product_line_id: data.product_line_id,
            product_line: data.product_line?.name,
            name: data.name,
            updated_by: user?.name
        }

        axios.patch(url, payload).then((response) => {
            // Queue audit log
            queueAuditLog(url, response.config.method.toUpperCase(), "Edit Product Type Data", user?.name, { old: oldData, new: payload }, null, moment().format('YYYY-MM-DD HH:mm:ss'), response.status)

            // Clear SWR cache
            cache.clear()

            // Navigate page
            navigate({ pathname: '/product/type' })

            // Show toast message
            toast.success(response.data.message, {
                id: "submit"
            })
        }).catch((error) => {
            // Assign validation message
            if (error.response.status === 422) {
                setErrors(error.response.data.errors)
            }

            // Show toast message
            toast.error(error.response.data.message, {
                ariaProps: {
                    superscript: error.response.status
                },
                id: "submit"
            })
        })
    }

    return (
        <Transition type="slide" reversed={true}>
            <Form data={loaderData} errors={errors} onSubmit={handleSubmit} />
        </Transition>
    )
}
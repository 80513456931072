import { useState, useEffect } from "react"

import ErrorMessage from "components/forms/error-message"
import lodash from "lodash"
import deepdash from "deepdash"
import { lang } from "config"
import Label from "components/forms/label"
import Input from "components/forms/input"
import useProductClassRepositories from "repositories/product-class"
import Description from "components/forms/description"
import nProgress from "nprogress"
import toast from "react-hot-toast"
import SelectDescription from "components/forms/select-description"
import { usePage } from "contexts/page-context"

export default function Form({ data, errors, onSubmit }) {
    const _ = deepdash(lodash)

    const { configuration } = usePage()

    const { data: productClassSelection, isLoading: isLoadingProductClassSelection } = useProductClassRepositories({
        paginate: false
    })

    const id = data?.id

    const [name, setName] = useState(data?.name ?? null)
    const [productClass, setProductClass] = useState(data?.product_class?.id ?? null)

    const [productClassObject, setProductClassObject] = useState()

    useEffect(() => {
        if (data) {
            setName(data.name ?? null)
            setProductClass(data.product_class.id ?? null)
        } else {
            if (!configuration('hierarchy').find((row) => row.key === 'product-class').value && configuration('hierarchy').find((row) => row.key === 'product-line').default_parent === 'auto' && !isLoadingProductClassSelection) {
                setProductClass(productClassSelection[0]?.id)
            }
        }
    }, [data, isLoadingProductClassSelection])

    useEffect(() => {
        if (productClass && !isLoadingProductClassSelection) {
            setProductClassObject(productClassSelection.find((row) => row.id === productClass))
        }
    }, [productClass, isLoadingProductClassSelection])

    const handleSubmit = (e) => {
        e.preventDefault()

        if (!nProgress.isStarted()) {
            onSubmit({
                id,
                name,
                product_class_id: productClass,
                product_class: productClassObject
            })
        } else {
            toast.error("Previous action is loading")
        }
    }
    return (
        <form onSubmit={handleSubmit} className="mt-8 space-y-8">
            <div className="mt-10 sm:mt-0">
                <div className="md:grid md:grid-cols-3 md:gap-6">
                    <div className="md:col-span-1">
                        <div className="px-4 sm:px-0">
                            <h3 className="text-lg font-medium leading-6 text-gray-900">{lang.main_form}</h3>
                            <p className="mt-1 text-sm text-gray-600">{lang.please_fill_the_provided_form_input}</p>
                        </div>
                    </div>
                    <div className="mt-5 md:col-span-2 md:mt-0">
                        <div className="border sm:rounded-xl">
                            <div className="px-4 py-5 sm:p-6">
                                <div className="grid grid-cols-2 gap-4">
                                    {configuration('hierarchy').find((row) => row.key === 'product-class').value && (
                                        <div>
                                            <Label htmlFor="class" value="Product Class" />
                                            <SelectDescription onChange={(value) => setProductClass(value)} selection={productClassSelection} isLoading={isLoadingProductClassSelection} value={productClass} keyValue={(value) => value.id} title={(value) => value.name} placeholder="Select Product Class" />
                                            <ErrorMessage error={errors.product_class_id} />
                                        </div>
                                    )}
                                    <div>
                                        <Label htmlFor="name" value={lang.name} />
                                        <Input placeholder="Input name here" onChange={(e) => setName(e.target.value)} value={name} id="name" error={errors.name} />
                                        <Description error={errors.name} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="mt-8 text-xs text-right">
                <button type="submit" className="items-center px-6 py-3 text-white transition bg-neutral-800 rounded-xl active:hover:scale-90">
                    <span>{`${_.isEmpty(data) ? lang.create : lang.update}`}</span>
                </button>
            </div>
        </form>
    )
}
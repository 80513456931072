import { useState, useEffect } from "react"

import ErrorMessage from "components/forms/error-message"
import lodash, { orderBy } from "lodash"
import deepdash from "deepdash"
import { lang } from "config"
import Label from "components/forms/label"
import nProgress from "nprogress"
import toast from "react-hot-toast"
import SelectDescription from "components/forms/select-description"
import { usePage } from "contexts/page-context"
import useProductUnitRepositories from "repositories/product-unit"
import useBranchRepositories from "repositories/branch"
import SelectSearchable from "components/forms/select-searchable"

export default function Form({ data, errors, onSubmit }) {
    const _ = deepdash(lodash)

    const { configuration } = usePage()

    const { data: productUnitSelection, isLoading: isLoadingProductUnitSelection } = useProductUnitRepositories({
        paginate: false,
        order_by: "long_name",
        direction: "asc"
    })

    const { data: branchSelection, isLoading: isLoadingBranchSelection } = useBranchRepositories({
        paginate: false,
        order_by: "name",
        direction: "asc"
    })

    const id = data?.id

    const [productUnit, setProductUnit] = useState(data?.product_unit_id ?? null)
    const [branch, setBranch] = useState(data?.branch_id ?? null)

    const [productUnitObject, setProductUnitObject] = useState()
    const [branchObject, setBranchObject] = useState()

    useEffect(() => {
        if (data) {
            setProductUnit(data.product_unit_id ?? null)
            setBranch(data.branch_id ?? null)
        }
    }, [data, isLoadingProductUnitSelection])

    useEffect(() => {
        if (productUnit && !isLoadingProductUnitSelection) {
            setProductUnitObject(productUnitSelection.find((row) => row.id === productUnit))
        }
    }, [productUnit, isLoadingProductUnitSelection])

    useEffect(() => {
        if (branch && !isLoadingBranchSelection) {
            setBranchObject(branchSelection.find((row) => row.id === branch))
        }
    }, [branch, isLoadingBranchSelection])

    const handleSubmit = (e) => {
        e.preventDefault()

        if (!nProgress.isStarted()) {
            onSubmit({
                id,
                product_unit_id: productUnit,
                product_unit: productUnitObject,
                branch_id: branch,
                branch: branchObject
            })
        } else {
            toast.error("Previous action is loading")
        }
    }
    return (
        <form onSubmit={handleSubmit} className="mt-8 space-y-8">
            <div className="mt-10 sm:mt-0">
                <div className="md:grid md:grid-cols-3 md:gap-6">
                    <div className="md:col-span-1">
                        <div className="px-4 sm:px-0">
                            <h3 className="text-lg font-medium leading-6 text-gray-900">{lang.main_form}</h3>
                            <p className="mt-1 text-sm text-gray-600">{lang.please_fill_the_provided_form_input}</p>
                        </div>
                    </div>
                    <div className="mt-5 md:col-span-2 md:mt-0">
                        <div className="border sm:rounded-xl">
                            <div className="px-4 py-5 sm:p-6">
                                <div className="grid grid-cols-2 gap-4">
                                    <div>
                                        <Label htmlFor="unit" value="Product Unit" />
                                        <SelectSearchable error={errors.product_unit_id} filter={(value) => value.name} onChange={(value) => setProductUnit(value)} selection={productUnitSelection} isLoading={isLoadingProductUnitSelection} value={productUnit} keyValue={(value) => value.id} title={(value) => value.name} description={(value) => value.long_name} placeholder="Select Product Unit" />
                                        <ErrorMessage error={errors.product_unit_id} />
                                    </div>
                                    <div>
                                        <Label htmlFor="branch" value="Branch" />
                                        <SelectDescription error={errors.branch_id} onChange={(value) => setBranch(value)} selection={branchSelection} isLoading={isLoadingBranchSelection} value={branch} keyValue={(value) => value.id} title={(value) => value.name} description={(value) => value.code} placeholder="Select Branch" />
                                        <ErrorMessage error={errors.branch_id} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="mt-8 text-xs text-right">
                <button type="submit" className="items-center px-6 py-3 text-white transition bg-neutral-800 rounded-xl active:hover:scale-90">
                    <span>{`${_.isEmpty(data) ? lang.create : lang.update}`}</span>
                </button>
            </div>
        </form>
    )
}
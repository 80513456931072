import { useEffect, useState } from 'react'
import { Modal } from 'components'
import ErrorMessage from 'components/forms/error-message'
import { lang } from 'config'
import _ from 'lodash'
import { Plus } from 'components/icons/outline'
import nProgress from 'nprogress'
import Label from 'components/forms/label'
import InputDate from 'components/forms/input-date'
import moment from 'moment'
import Input from 'components/forms/input'
import { Copy } from 'components/icons/outline'
import useProductUnitRepositories from 'repositories/product-unit'
import { toast } from 'react-hot-toast'
import axios from 'axios'
import SelectDescription from 'components/forms/select-description'
import SelectSearchable from 'components/forms/select-searchable'

const Content = ({ onSubmit }) => {
    const { data: productUnitSelection, isLoading: isLoadingProductUnitSelection } = useProductUnitRepositories({
        paginate: false,
        order_by: "long_name",
        direction: "asc"
    })

    const [productUnitId, setProductUnitId] = useState()
    const [productUnit, setProductUnit] = useState()

    const [errors, setErrors] = useState({})

    useEffect(() => {
        if (productUnitId && !isLoadingProductUnitSelection) {
            setProductUnit(productUnitSelection?.find((row) => row.id === productUnitId))
        }
    }, [productUnitId, isLoadingProductUnitSelection])

    const handleSubmit = (e) => {
        e.preventDefault()

        nProgress.start()

        axios.get(`${process.env.REACT_APP_INVENTORY_API_URL}/product/unit/${productUnitId}`, {
        }).then((response) => {
            toast.success(response.data.message)

            onSubmit(response.data.data)

            nProgress.done()
        }).catch((error) => {
            if (error.response.status === 422) {
                setErrors(error.response.data.errors)
            }
            toast.error(error.response.data.message)

            console.log(error.response)

            nProgress.done()
        })
    }

    return (
        <div className="mt-8 space-y-8">
            <div className="space-y-4">
                <div>
                    <Label htmlFor="product_unit" value="Product Unit" />
                    {/* <select onChange={(e) => setProductUnitId(e.target.value)} value={productUnitId} name="product_unit" id="productUnit" className={`${errors.product_unit_id ? 'border-red-200' : 'border-neutral-200'} mt-1 block w-full px-2 py-2 text-sm transition border focus:outline-none rounded-xl focus:border-neutral-400 focus:ring focus:ring-neutral-200`}>
                            <option value={null} selected disabled>-- Select Product Unit --</option>
                            {productUnitSelection?.length !== 0 && productUnitSelection?.map(row => (
                                <option value={row.id}>{row.name}</option>
                            ))}
                        </select> */}
                    <SelectSearchable filter={(value) => value.long_name} onChange={(value) => setProductUnitId(value)} selection={productUnitSelection} isLoading={isLoadingProductUnitSelection} value={productUnitId} keyValue={(value) => value.id} title={(value) => value.name} description={(value) => value.long_name} placeholder="Select Product Unit" />
                    <ErrorMessage error={errors.product_unit_id} />
                </div>
            </div>
            <div className="text-xs">
                <button onClick={handleSubmit} className="items-center px-6 py-3 text-white transition bg-neutral-800 rounded-xl active:hover:scale-90">
                    <span>Submit</span>
                </button>
            </div>
        </div>
    )
}

const Clone = ({ onSubmit }) => {
    const [isOpen, setIsOpen] = useState(false)

    const openModal = () => setIsOpen(true)
    const closeModal = () => setIsOpen(false)

    const handleSubmit = (data) => {
        onSubmit(data)

        closeModal()
    }

    return (
        <>
            <button type="button" onClick={openModal} className="inline-flex items-center px-4 py-3 space-x-2 transition border border-neutral-200 bg-neutral-50 rounded-xl active:hover:scale-90">
                <Copy className="w-4 h-4" stroke={1.5} />
                <span>Copy Data from Existing</span>
            </button>
            <Modal isOpen={isOpen} onClose={closeModal} title="Select Data" content={<Content onSubmit={handleSubmit} />} />
        </>
    )
}

export default Clone
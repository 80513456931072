import { useEffect, useRef, useState } from "react"
import { Link, useLocation, useNavigate, useOutletContext, useSearchParams } from "react-router-dom"

import moment from "moment/moment"
import Delete from "pages/product/unit/delete"
import Filter from "pages/product/unit/filter"
import nProgress from "nprogress"
import { useAuth } from "contexts/auth-context"
import { Pagination } from "components"
import { lang } from "config"
import { CloudDownload, Pencil, Plus } from "components/icons/outline"
import { usePage } from "contexts/page-context"
import useProductUnitRepositories from "repositories/product-unit"

import { motion } from "framer-motion"
import { slideVariant, defaultVariant } from "components/transition"
import { PhotoOff } from "components/icons/outline"
import Table from "components/table"
import axios from "axios"
import toast from "react-hot-toast"
import _ from "lodash"

export default function List() {
    // Logged in user ability
    const { can, user } = useAuth()
    // Configuration
    const { configuration } = usePage()

    // Transition
    const { title, transition, setTransition } = useOutletContext()

    // React router navigate hook
    const navigate = useNavigate()

    // React router location hook
    const location = useLocation()

    // Query parameters
    const [searchParams, setSearchParams] = useSearchParams()
    const [searchTemp, setSearchTemp] = useState(searchParams.get('search'))

    const searchInput = useRef()

    const page = searchParams.get('page')
    const search = searchParams.get('search')
    const name = searchParams.get('name')
    const from = searchParams.get('from')
    const to = searchParams.get('to')

    const orderBy = searchParams.get('order_by')
    const direction = searchParams.get('direction')

    const { data: productUnitData, isLoading: isLoadingProductUnitData, mutate: mutateProductUnitData } = useProductUnitRepositories({
        page,
        search,
        name,
        from,
        to,

        order_by: orderBy,
        direction
    })

    useEffect(() => {
        window.history.replaceState({}, document.title)

        if (transition.reverse) {
            setTransition({ type: 'fade', reverse: false })
        }
    }, [])

    useEffect(() => {
        searchInput.current.focus()
    }, [search])

    useEffect(() => {
        const timeOut = setTimeout(() => {
            if (searchTemp !== search) {
                setSearchParams({ search: searchTemp })
            }
        }, 500)

        return () => clearTimeout(timeOut)
    }, [searchTemp])

    // Watch changes on petty cash data and loading state
    useEffect(() => {
        if (search === "") {
            navigate(``, { replace: true })
        }

        if (isLoadingProductUnitData) {
            nProgress.start()
        } else {
            nProgress.done()
        }
    }, [productUnitData, isLoadingProductUnitData])

    // Data filter handlers
    const updateFilter = (value) => {
        setSearchParams(value)
    }

    const removeFilter = () => {
        setSearchParams({})
    }

    // Data sort handlers
    const toggleSort = (value) => {
        if ((direction === null || direction === 'desc') || orderBy !== value) {
            setSearchParams({
                order_by: value,
                direction: 'asc'
            })
        } else {
            setSearchParams({
                order_by: value,
                direction: 'desc'
            })
        }
    }

    // Data list props
    const dataListHeaders = [
        {
            isAction: false,
            content: "Product Unit",
            sortKey: "name"
        },
        ...configuration('hierarchy').find((row) => row.key === 'product-model').value ? [{
            isAction: false,
            content: "Product Model",
            sortKey: "product_model.name"
        }] : [],
        {
            isAction: false,
            content: "SKU",
            sortKey: "sku"
        },
        {
            isAction: false,
            content: "Dibuat Pada",
            sortKey: "created_at"
        },
        {
            isAction: false,
            content: "Diubah Pada",
            sortKey: "updated_at"
        },
        {
            isAction: true,
            content: null
        }
    ]

    const dataListColumns = [
        ...configuration('hierarchy').find((row) => row.key === 'product-model').value ? [{
            type: "default",
            bold: false,
            content: (value) => (
                <div className="flex items-center">
                    {value.image ? (
                        <a href={value.image} target="_blank" className="w-10 h-10">
                            <img className="object-cover rounded-full aspect-square" src={value.image} alt="Product Image" />
                        </a>
                    ) : (
                        <div className="flex items-center justify-center w-10 h-10 rounded-full bg-neutral-100">
                            <PhotoOff className="w-5 h-5" strokeWidth={1.5} />
                        </div>
                    )}
                    <div className="ml-4 space-y-1">
                        <div>{value.name}</div>
                        <div className="font-medium text-neutral-900">{value.long_name}</div>
                    </div>
                </div>
            )
        }] : [],
        {
            type: "default",
            bold: false,
            content: (value) => (
                <div className="flex flex-col space-y-1">
                    <div>{value.product_model?.name}</div>
                    <div className="font-medium text-neutral-900">{value.product_model?.long_name}</div>
                </div>
            )
        },
        {
            type: "default",
            bold: false,
            content: (value) => value.sku
        },
        {
            type: "default",
            bold: false,
            content: (value) => moment(value.created_at).format('MMMM D, YYYY')
        },
        {
            type: "default",
            bold: false,
            content: (value) => moment(value.updated_at).format('MMMM D, YYYY')
        },
        {
            type: "action",
            content: (value) => (
                <>
                    {can('product-unit.update') && (
                        <Link to={`${value.id}/edit`} state={{ back: title, from: location?.pathname, transition: 'slide' }} className="inline-flex items-center p-1 text-white transition rounded-full bg-neutral-800 active:hover:scale-90">
                            <Pencil className="w-6 h-6" />
                        </Link>
                    )}

                    {can('product-unit.delete') && (
                        <Delete data={value} onSuccess={() => mutateProductUnitData()} />
                    )}
                </>
            )
        }
    ]

    // Export data function
    const exportData = (type) => {
        const timestamp = new Date().toISOString()

        toast.loading("Loading", {
            id: `toast-${timestamp}`
        })

        axios.post(`${process.env.REACT_APP_INVENTORY_API_URL}/product/unit/export`, {
            type,
            search,
            name,
            from,
            to,
            phone: user?.phone
        }).then((response) => {
            toast.success(response.data.message, {
                id: `toast-${timestamp}`
            })
        }).catch((error) => {
            toast.error(error.response.data.message, {
                id: `toast-${timestamp}`
            })
        })
    }

    return (
        // <motion.div variants={defaultVariant} initial="initial" animate="animate" exit="exit" className="space-y-6">
        <motion.div className="space-y-6">
            <div className="flex items-center justify-between text-xs">
                <div className="flex items-center sm:space-x-2">
                    <div className="relative hidden sm:block">
                        <div className="absolute inset-y-0 flex items-center pl-3 pointer-events-none">
                            <svg xmlns="http://www.w3.org/2000/svg" className="w-4 h-4" width={24} height={24} viewBox="0 0 24 24" strokeWidth={1} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                <circle cx={10} cy={10} r={7}></circle>
                                <line x1={21} y1={21} x2={15} y2={15}></line>
                            </svg>
                        </div>
                        <input ref={searchInput} onChange={(e) => setSearchTemp(e.target.value)} value={searchTemp} type="text" placeholder={`${lang.search} Product Unit`} autoComplete="off" className="w-64 py-3 pl-8 pr-4 text-xs transition border border-neutral-200 focus:outline-none rounded-xl focus:border-neutral-400 focus:ring focus:ring-neutral-200" />
                    </div>
                    <Filter onSubmit={updateFilter} onRemove={removeFilter} data={Object.fromEntries(Object.entries({ name, from, to }).filter(([_, v]) => v != null))} />
                </div>
                <div className="flex items-center space-x-2">
                    <button onClick={() => exportData('xlsx')} className="inline-flex items-center px-4 py-3 space-x-2 text-white transition bg-neutral-800 rounded-xl active:hover:scale-90">
                        <CloudDownload className="w-4 h-4" stroke={1.5} />
                        <span>Export Data</span>
                    </button>
                    {can('product-unit.create') && (
                        <div>
                            <Link to="create" onClick={() => { setTransition({ type: 'slide', reverse: true }) }} state={{ back: title, from: location?.pathname, transition: { type: 'slide', reverse: true } }} className="inline-flex items-center px-4 py-3 space-x-2 text-white transition bg-neutral-800 rounded-xl active:hover:scale-90">
                                <Plus className="w-4 h-4" stroke={1.5} />
                                <span>{lang.create}</span>
                            </Link>
                        </div>
                    )}
                </div>
            </div>
            <Table isLoading={isLoadingProductUnitData} data={productUnitData?.data ?? []} headers={dataListHeaders} columns={dataListColumns} key={(value) => value.id} isEmpty={productUnitData?.data.length === 0 && !search && !isLoadingProductUnitData} isEmptyOnSearching={productUnitData?.data.length === 0 && search && !isLoadingProductUnitData} toggleSort={toggleSort} orderBy={orderBy} direction={direction} />
            <Pagination links={productUnitData?.links} from={productUnitData?.from} to={productUnitData?.to} total={productUnitData?.total} />
        </motion.div>
    )
}
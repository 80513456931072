import { useEffect, useRef } from "react"
import { Link, useLocation, useNavigate, useOutletContext, useSearchParams } from "react-router-dom"
import moment from "moment/moment"
import Delete from "pages/reward/delete"
import Filter from "pages/reward/filter"
import nProgress from "nprogress"
import { useAuth } from "contexts/auth-context"
import { Pagination } from "components"
import { lang } from "config"
import { Pencil, Plus } from "components/icons/outline"
import { usePage } from "contexts/page-context"
import useDiscountRepositories from "repositories/discount"
import useRewardRepositories from "repositories/reward"
import Table from "components/table"

export default function List() {
    // Logged in user ability
    const { can, isSuper, currentBranch } = useAuth()
    // Configuration
    const { configuration } = usePage()

    // Transition
    const { title, transition, setTransition } = useOutletContext()

    // React router navigate hook
    const navigate = useNavigate()

    // React router location hook
    const location = useLocation()

    // Query parameters
    const [searchParams, setSearchParams] = useSearchParams()

    const searchInput = useRef()

    const page = searchParams.get('page')
    const search = searchParams.get('search')
    const name = searchParams.get('name')
    const from = searchParams.get('from')
    const to = searchParams.get('to')

    const orderBy = searchParams.get('order_by')
    const direction = searchParams.get('direction')

    const { data: rewardData, isLoading: isLoadingRewardData, mutate: mutateRewardData } = useRewardRepositories({
        page,
        search,
        name,
        from,
        to,

        order_by: orderBy,
        direction
    })

    useEffect(() => {
        window.history.replaceState({}, document.title)

        if (transition.reverse) {
            setTransition({ type: 'slide', reverse: false })
        }
    }, [])

    useEffect(() => {
        searchInput.current.focus()
    }, [search])

    // Watch changes on petty cash data and loading state
    useEffect(() => {
        if (search === "") {
            navigate(``, { replace: true })
        }

        if (isLoadingRewardData) {
            nProgress.start()
        } else {
            nProgress.done()
        }
    }, [rewardData, isLoadingRewardData])

    // Data filter handlers
    const updateFilter = (value) => {
        setSearchParams(value)
    }

    const removeFilter = () => {
        setSearchParams({})
    }

    // Data sort handlers
    const toggleSort = (value) => {
        if ((direction === null || direction === 'desc') || orderBy !== value) {
            setSearchParams({
                order_by: value,
                direction: 'asc'
            })
        } else {
            setSearchParams({
                order_by: value,
                direction: 'desc'
            })
        }
    }

    // Data list props
    const dataListHeaders = [
        {
            isAction: false,
            content: "Nama",
            sortKey: "name"
        },
        {
            isAction: false,
            content: "Sales Item Count",
            sortKey: "sales_item_count"
        },
        {
            isAction: false,
            content: "Starting Item Number",
            sortKey: "starting_item_number"
        },
        {
            isAction: false,
            content: "Percentage",
            sortKey: "percentage"
        },
        {
            isAction: false,
            content: "Amount",
            sortKey: "amount"
        },
        {
            isAction: false,
            content: "Multiply",
            sortKey: "multiply"
        },
        {
            isAction: false,
            content: "Dibuat Pada",
            sortKey: "created_at"
        },
        {
            isAction: false,
            content: "Diubah Pada",
            sortKey: "updated_at"
        },
        {
            isAction: true,
            content: null
        }
    ]

    const dataListColumns = [
        {
            type: "default",
            bold: true,
            content: (value) => value.name
        },
        {
            type: "default",
            bold: false,
            content: (value) => value.sales_item_count
        },
        {
            type: "default",
            bold: false,
            content: (value) => value.starting_item_number
        },
        {
            type: "default",
            bold: false,
            content: (value) => value.percentage ? `${value.percentage}%` : '-'
        },
        {
            type: "default",
            bold: false,
            content: (value) => value.amount ? Intl.NumberFormat('id-Id', { style: 'currency', currency: 'idr' }).format(value.amount) : '-'
        },
        {
            type: "default",
            bold: false,
            content: (value) => value.multiply ? 'Yes' : 'No'
        },
        {
            type: "default",
            bold: false,
            content: (value) => moment(value.created_at).format('MMMM D, YYYY')
        },
        {
            type: "default",
            bold: false,
            content: (value) => moment(value.updated_at).format('MMMM D, YYYY')
        },
        {
            type: "action",
            content: (value) => (
                <>
                    {can('reward.update') && (
                        <Link to={`${value.id}/edit`} state={{ back: title, from: location?.pathname, transition: 'slide' }} className="inline-flex items-center p-1 text-white transition rounded-full bg-neutral-800 active:hover:scale-90">
                            <Pencil className="w-6 h-6" />
                        </Link>
                    )}

                    {can('reward.delete') && (
                        <Delete data={value} onSuccess={() => mutateRewardData()} />
                    )}
                </>
            )
        }
    ]

    return (
        <div className="space-y-6">
            <div className="flex items-center justify-between text-xs">
                <div className="flex items-center sm:space-x-2">
                    <div className="relative hidden sm:block">
                        <div className="absolute inset-y-0 flex items-center pl-3 pointer-events-none">
                            <svg xmlns="http://www.w3.org/2000/svg" className="w-4 h-4" width={24} height={24} viewBox="0 0 24 24" strokeWidth={1} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                <circle cx={10} cy={10} r={7}></circle>
                                <line x1={21} y1={21} x2={15} y2={15}></line>
                            </svg>
                        </div>
                        <input ref={searchInput} onChange={(e) => setSearchParams({ search: e.target.value })} value={search} type="text" placeholder={`${lang.search} Reward`} autoComplete="off" className="w-64 py-3 pl-8 pr-4 text-xs transition border border-neutral-200 focus:outline-none rounded-xl focus:border-neutral-400 focus:ring focus:ring-neutral-200" />
                    </div>
                    <Filter onSubmit={updateFilter} onRemove={removeFilter} data={Object.fromEntries(Object.entries({ name, from, to }).filter(([_, v]) => v != null))} />
                </div>
                <div className="flex items-center space-x-2">
                    {can('reward.create') && (
                        <div>
                            <Link to="create" onClick={() => { setTransition({ type: 'slide', reverse: true }) }} state={{ back: title, from: location?.pathname, transition: { type: 'slide' } }} className="inline-flex items-center px-4 py-3 space-x-2 text-white transition bg-neutral-800 rounded-xl active:hover:scale-90">
                                <Plus className="w-4 h-4" stroke={1.5} />
                                <span>{lang.create}</span>
                            </Link>
                        </div>
                    )}
                </div>
            </div>
            <Table isLoading={isLoadingRewardData} data={rewardData?.data ?? []} headers={dataListHeaders} columns={dataListColumns} key={(value) => value.id} isEmpty={rewardData?.data.length === 0 && !search && !isLoadingRewardData} isEmptyOnSearching={rewardData?.data.length === 0 && search && !isLoadingRewardData} toggleSort={toggleSort} orderBy={orderBy} direction={direction} />
            <Pagination links={rewardData?.links} from={rewardData?.from} to={rewardData?.to} total={rewardData?.total} />
        </div>
    )
}
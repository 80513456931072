import { useState } from 'react'
import { Modal } from 'components'
import axios from 'axios'
import nProgress from 'nprogress'
import toast from 'react-hot-toast'
import { checkDue } from 'helpers/date-helper'
import { lang } from 'config'
import moment from 'moment'
import { useAuth } from 'contexts/auth-context'
import { useAuditLogContext } from 'contexts/audit-log-context'

const Content = ({ data, success }) => {
    const { user } = useAuth()
    
    const { queueAuditLog } = useAuditLogContext()

    const handleSubmit = (e) => {
        e.preventDefault()
        nProgress.start()

        const url = `${process.env.REACT_APP_INVENTORY_API_URL}/attribute/${data?.id}`
        const payload = {
            attribute_group_id: data.attribute_group_id,
            attribute_group: data.group?.name,
            name: data.name,
            created_by: data.created_by,
            updated_by: data.updated_by,
            deleted_by: user?.name
        }

        axios.delete(url)
            .then((response) => {
                // Queue audit log
                queueAuditLog(url, response.config.method.toUpperCase(), "Delete Attribute Data", user?.name, payload, null, moment().format('YYYY-MM-DD HH:mm:ss'), response.status)

                // Dispatch parent success function
                success()

                // Show toast message
                toast.success(response.data.message)
            }).catch((error) => {
                // Show toast message
                toast.error(error.response.data.message)
            })
    }

    return (
        <form onSubmit={handleSubmit} className="mt-8 space-y-8">
            {lang.are_you_sure_you_want_to_delete} <span className="inline-flex px-3 py-1 text-sm font-medium rounded-xl bg-neutral-200 text-neutral-600">{data?.name}</span> ?
            <div className="text-xs">
                <button type="submit" className="items-center px-6 py-3 text-white transition bg-neutral-800 rounded-xl active:hover:scale-90">
                    <span>{lang.delete}</span>
                </button>
            </div>
        </form>
    )
}

const Delete = ({ data, onSuccess }) => {
    const [isOpen, setIsOpen] = useState(false)

    const openModal = () => setIsOpen(true)
    const closeModal = () => setIsOpen(false)

    const handleSuccess = () => {
        closeModal()
        onSuccess()
    }

    return (
        <>
            <button disabled={false} onClick={openModal} class={`${false ? 'opacity-50 bg-red-900' : 'bg-red-800'} inline-flex items-center p-1 text-white transition rounded-full active:hover:scale-90`}>
                <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" width={24} height={24} viewBox="0 0 24 24" strokeWidth={1} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <line x1={4} y1={7} x2={20} y2={7}></line>
                    <line x1={10} y1={11} x2={10} y2={17}></line>
                    <line x1={14} y1={11} x2={14} y2={17}></line>
                    <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12"></path>
                    <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3"></path>
                </svg>
            </button>
            <Modal isOpen={isOpen} onClose={closeModal} title={`${lang.delete} ${lang.data}`} content={<Content data={data} success={handleSuccess} />} />
        </>
    )
}

export default Delete
import { useState } from 'react'
import { Modal } from 'components'
import ErrorMessage from 'components/forms/error-message'
import { lang } from 'config'
import _ from 'lodash'
import { Plus } from 'components/icons/outline'
import nProgress from 'nprogress'
import Label from 'components/forms/label'
import InputDate from 'components/forms/input-date'
import moment from 'moment'
import Input from 'components/forms/input'
import axios from 'axios'
import toast from 'react-hot-toast'
import useBranchRepositories from 'repositories/branch'
import SelectDescription from 'components/forms/select-description'
import InputAmount from 'components/forms/input-amount'
import { usePage } from 'contexts/page-context'

const Content = ({ onSubmit }) => {
    const { configuration } = usePage()

    const { data: branchSelection, isLoading: isLoadingBranchSelection } = useBranchRepositories({
        paginate: false
    })

    const [purchaseDate, setPurchaseDate] = useState()
    const [dosDate, setDosDate] = useState()
    const [outletId, setOutletId] = useState(configuration('site') === 'hq' ? 1 : null)
    const [purchaseOrderId, setPurchaseOrderId] = useState()
    const [purchaseStock, setPurchaseStock] = useState()
    const [currentStock, setCurrentStock] = useState()

    const [amount, setAmount] = useState()
    const [hpp, setHpp] = useState()
    const [maxRetailPrice, setMaxRetailPrice] = useState()

    const [errors, setErrors] = useState({})

    const handleSubmit = (e) => {
        e.preventDefault()

        if (!nProgress.isStarted()) {
            nProgress.start()

            toast.loading("Loading", {
                id: "submit"
            })

            axios.post(`${process.env.REACT_APP_INVENTORY_API_URL}/product/unit/validate`, {
                type: 'stock',
                purchase_date: purchaseDate,
                dos_date: dosDate,
                outlet_id: outletId,
                purchase_order_id: purchaseOrderId,
                purchase_stock: purchaseStock,
                current_stock: purchaseStock,
                amount,
                hpp_amount: hpp,
                maximum_retail_price: maxRetailPrice
            }).then((response) => {
                toast.success(response.data.message, {
                    id: "submit"
                })

                onSubmit({
                    purchase_date: purchaseDate,
                    dos_date: dosDate,
                    outlet_id: outletId,
                    purchase_order_id: purchaseOrderId,
                    purchase_stock: purchaseStock,
                    current_stock: purchaseStock,
                    amount,
                    hpp_amount: hpp,
                    maximum_retail_price: maxRetailPrice
                })

                nProgress.done()
            }).catch((error) => {
                if (error.response.status === 422) {
                    setErrors(error.response.data.errors)
                }
                toast.error(error.response.data.message, {
                    id: "submit"
                })

                console.log(error.response)

                nProgress.done()
            })
        } else {
            toast.error("Previous action is loading")
        }
    }

    return (
        <div className="mt-8 space-y-8">
            <div className="space-y-4">
                <div className="grid grid-cols-2 gap-4">
                    <div>
                        <Label htmlFor="outlet_id" value="Outlet ID" />
                        <SelectDescription onChange={(value) => setOutletId(value)} selection={branchSelection} isLoading={isLoadingBranchSelection} value={outletId} keyValue={(value) => value.id} title={(value) => value.name} description={(value) => value.code} placeholder="Select Outlet" error={errors.outlet_id} />
                        <ErrorMessage error={errors.outlet_id} />
                    </div>

                    <div></div>

                    <div>
                        <Label htmlFor="purchase_date" value="Purchase Date" />
                        <InputDate error={errors.purchase_date} id="purchase_date" onChange={(value) => setPurchaseDate(moment(value).format('Y-MM-DD'))} value={Date.parse(purchaseDate)} selected={Date.parse(purchaseDate)} />
                        <ErrorMessage error={errors.purchase_date} />
                    </div>

                    <div>
                        <Label htmlFor="dos_date" value="DoS Date" />
                        <InputDate error={errors.dos_date} id="dos_date" onChange={(value) => setDosDate(moment(value).format('Y-MM-DD'))} value={Date.parse(dosDate)} selected={Date.parse(dosDate)} />
                        <ErrorMessage error={errors.dos_date} />
                    </div>

                    <div>
                        <Label htmlFor="purchase_order_id" value="Purchase Order ID" />
                        <Input placeholder="PO_202309210001" onChange={(e) => setPurchaseOrderId(e.target.value)} value={purchaseOrderId} id="purchase_order_id" error={errors.purchase_order_id} />
                        <ErrorMessage error={errors.purchase_order_id} />
                    </div>

                    <div>
                        <Label htmlFor="purchase_stock" value="Purchase Stock" />
                        <Input placeholder="1" type="number" onChange={(e) => setPurchaseStock(e.target.value)} value={purchaseStock} id="purchase_stock" error={errors.purchase_stock} />
                        <ErrorMessage error={errors.purchase_stock} />
                    </div>

                    <div>
                        <Label htmlFor="amount" value="Amount" />
                        <InputAmount onChange={(value) => setAmount(value)} value={parseFloat(amount)} id="amount" error={errors.amount} />
                        <ErrorMessage error={errors.amount} />
                    </div>

                    <div>
                        <Label htmlFor="hpp" value="HPP Amount" />
                        <InputAmount onChange={(value) => setHpp(value)} value={parseFloat(hpp)} id="hpp" error={errors.hpp_amount} />
                        <ErrorMessage error={errors.hpp_amount} />
                    </div>

                    {configuration('site') === 'go' && (
                        <div>
                            <Label htmlFor="max_retail_price" value="Maximum Retail Price" />
                            <InputAmount onChange={(value) => setMaxRetailPrice(value)} value={parseFloat(maxRetailPrice)} id="max_retail_price" error={errors.maximum_retail_price} />
                            <ErrorMessage error={errors.maximum_retail_price} />
                        </div>
                    )}

                    {/* <div>
                        <Label htmlFor="current_stock" value="Current Stock" />
                        <Input placeholder="1" type="number" onChange={(e) => setCurrentStock(e.target.value)} value={currentStock} id="current_stock" error={errors.current_stock} />
                        <ErrorMessage error={errors.current_stock} />
                    </div> */}
                </div>
            </div>
            <div className="text-xs">
                <button onClick={handleSubmit} className="items-center px-6 py-3 text-white transition bg-neutral-800 rounded-xl active:hover:scale-90">
                    <span>{lang.save}</span>
                </button>
            </div>
        </div>
    )
}

const Add = ({ onSubmit }) => {
    const [isOpen, setIsOpen] = useState(false)

    const openModal = () => setIsOpen(true)
    const closeModal = () => setIsOpen(false)

    const handleSubmit = (data) => {
        onSubmit(data)

        closeModal()
    }

    return (
        <>
            <button type="button" onClick={openModal} className="inline-flex items-center px-4 py-3 space-x-2 transition border border-neutral-200 bg-neutral-50 rounded-xl active:hover:scale-90">
                <Plus className="w-4 h-4" stroke={1.5} />
                <span>{lang.create}</span>
            </button>
            <Modal isOpen={isOpen} onClose={closeModal} title={`${lang.create} ${lang.data}`} content={<Content onSubmit={handleSubmit} />} />
        </>
    )
}

export default Add
import { useState, useEffect } from "react"

import ErrorMessage from "components/forms/error-message"
import lodash from "lodash"
import deepdash from "deepdash"
import { lang } from "config"
import Label from "components/forms/label"
import Input from "components/forms/input"
import InputAmount from "components/forms/input-amount"
import Checkbox from "components/forms/checkbox"
import Description from "components/forms/description"
import nProgress from "nprogress"
import toast from "react-hot-toast"
import { X } from "components/icons/outline"
import { PhotoOff } from "components/icons/outline"

import { Add as AddDependency } from "./dependency"
import { Add as AddGiveaway } from "./giveaway"
import { usePage } from "contexts/page-context"

export default function Form({ data, errors, onSubmit }) {
    const _ = deepdash(lodash)

    const { configuration } = usePage()

    const id = data?.id

    // Main
    const [name, setName] = useState(data?.name ?? null)
    const [sales, setSales] = useState(data?.sales_item_count ?? null)
    const [starting, setStarting] = useState(data?.starting_item_number ?? null)
    const [amount, setAmount] = useState(data?.amount ?? null)
    const [percentage, setPercentage] = useState(data?.percentage ?? null)
    const [multiply, setMultiply] = useState(data?.multiply ?? null)

    // Dependency
    const [isDepend, setIsDepend] = useState(data?.is_depend ?? null)
    const [dependency, setDependency] = useState(data?.dependencies ?? [])

    // Giveaway
    const [haveGiveaway, setHaveGiveaway] = useState(data?.have_giveaway ?? null)
    const [giveaway, setGiveaway] = useState(data?.giveaways ?? [])

    useEffect(() => {
        if (!isDepend) {
            setDependency([])
        }
    }, [isDepend])

    useEffect(() => {
        if (!haveGiveaway) {
            setGiveaway([])
        }
    }, [haveGiveaway])

    const handleSubmit = (e) => {
        e.preventDefault()

        const dependencyData = dependency.map((row) => {
            return {
                product_unit_id: row.product_unit.id,
                product_unit: row.product_unit.name,
                sales_item_count: row.sales_item_count
            }
        })

        const giveawayData = giveaway.map((row) => {
            return {
                product_unit_id: row.product_unit.id,
                product_unit: row.product_unit.name,
                give_away_item_count: row.give_away_item_count
            }
        })

        if (!nProgress.isStarted()) {
            onSubmit({
                id,
                name,
                sales_item_count: sales,
                starting_item_number: starting,
                amount,
                percentage,
                multiply,

                dependency: dependencyData,
                giveaway: giveawayData
            })
        } else {
            toast.error("Previous action is loading")
        }
    }

    const handleAddDependency = (data) => {
        setDependency([...dependency, data])
    }

    const handleRemoveDependency = (index) => {
        const value = dependency.filter((row, i) => i !== index)

        setDependency(value)
    }

    const handleAddGiveaway = (data) => {
        setGiveaway([...giveaway, data])
    }

    const handleRemoveGiveaway = (index) => {
        const value = giveaway.filter((row, i) => i !== index)

        setGiveaway(value)
    }

    return (
        <form onSubmit={handleSubmit} className="mt-8 space-y-8">
            <div className="mt-10 sm:mt-0">
                <div className="md:grid md:grid-cols-3 md:gap-6">
                    <div className="md:col-span-1">
                        <div className="px-4 sm:px-0">
                            <h3 className="text-lg font-medium leading-6 text-gray-900">{lang.main_form}</h3>
                            <p className="mt-1 text-sm text-gray-600">{lang.please_fill_the_provided_form_input}</p>
                        </div>
                    </div>
                    <div className="mt-5 md:col-span-2 md:mt-0">
                        <div className="border sm:rounded-xl">
                            <div className="px-4 py-5 sm:p-6">
                                <div className="grid grid-cols-2 gap-4">
                                    <div>
                                        <Label htmlFor="name" value="Name" />
                                        <Input placeholder="Buy Two Get One" onChange={(e) => setName(e.target.value)} value={name} id="name" error={errors.name} />
                                        <Description error={errors.name} />
                                    </div>
                                    <div></div>
                                    <div>
                                        <Label htmlFor="sales" value="Sales Item Count" />
                                        <Input placeholder="0" type="number" onChange={(e) => setSales(e.target.value)} value={sales} id="sales" error={errors.sales_item_count} />
                                        <ErrorMessage error={errors.sales_item_count} />
                                    </div>
                                    <div>
                                        <Label htmlFor="starting" value="Starting Item Number" />
                                        <Input placeholder="0" type="number" onChange={(e) => setStarting(e.target.value)} value={starting} id="starting" error={errors.starting_item_number} />
                                        <ErrorMessage error={errors.starting_item_number} />
                                    </div>
                                    <div>
                                        <Label htmlFor="amount" value="Amount" />
                                        <InputAmount placeholder="0" onChange={(value) => setAmount(value)} value={parseFloat(amount)} id="amount" error={errors.amount} />
                                        <ErrorMessage error={errors.amount} />
                                    </div>
                                    <div>
                                        <Label htmlFor="percentage" value="Percentage" />
                                        <Input type="number" placeholder="0-100" onChange={(e) => setPercentage(e.target.value)} value={percentage} id="percentage" error={errors.percentage} />
                                        <ErrorMessage error={errors.percentage} />
                                    </div>
                                    <div>
                                        <Label htmlFor="multiply" value="Multiply" />
                                        <Checkbox onChange={() => setMultiply(!multiply)} value={true} checked={multiply} id="multiply" error={errors.multiply} />
                                        <ErrorMessage error={errors.multiply} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {configuration('site') === 'go' && (
                <>
                    <div className="hidden sm:block" aria-hidden="true">
                        <div className="py-5">
                            <div className="border-t border-gray-200" />
                        </div>
                    </div>

                    <div className="mt-10 sm:mt-0">
                        <div className="md:grid md:grid-cols-3 md:gap-6">
                            <div className="md:col-span-1">
                                <div className="px-4 sm:px-0">
                                    <h3 className="text-lg font-medium leading-6 text-gray-900">Data Dependency</h3>
                                    <p className="mt-1 text-sm text-gray-600">Tentukan apakah diskon membutuhkan beberapa produk untuk aktif atau tidak.</p>
                                </div>
                            </div>
                            <div className="mt-5 md:col-span-2 md:mt-0">
                                <div className="border sm:rounded-xl">
                                    <div className="px-4 py-5 space-y-8 sm:p-6">
                                        <fieldset>
                                            <legend className="sr-only">Dependency</legend>
                                            <div className="text-base font-medium text-gray-900" aria-hidden="true">
                                                Dependency
                                            </div>
                                            <div className="mt-4 space-y-4">
                                                <div className="flex items-start">
                                                    <div className="flex items-center h-5">
                                                        <Checkbox id="is_depend" checked={isDepend} onChange={() => setIsDepend(!isDepend)} />
                                                    </div>
                                                    <div className="ml-3 text-sm">
                                                        <label htmlFor="is_depend" className="font-medium text-gray-700">
                                                            Is discount have dependency?
                                                        </label>
                                                        <p className="text-gray-500">Check if the discount have some product dependency.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </fieldset>
                                        {isDepend && (
                                            // Set min width to zero, fix fieldset overflowing parent.
                                            <fieldset className="min-w-0">
                                                <legend className="sr-only">Unit</legend>
                                                <div className="text-base font-medium text-gray-900" aria-hidden="true">
                                                    Unit
                                                </div>
                                                <div className="mt-4 space-y-4">
                                                    <div className="overflow-x-auto border border-neutral-200 rounded-xl">
                                                        <table className="min-w-full overflow-x-auto divide-y divide-neutral-200">
                                                            <thead className="bg-neutral-50 rounded-t-3xl">
                                                                <tr>
                                                                    <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">Product Unit</th>
                                                                    <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">Sales Item Count</th>
                                                                    {true && (
                                                                        <th scope="col" className="relative px-6 py-3"><span className="sr-only">{lang.action}</span></th>
                                                                    )}
                                                                </tr>
                                                            </thead>
                                                            <tbody className="bg-white divide-y divide-neutral-200">
                                                                {dependency.length === 0 && (
                                                                    <tr className="text-center">
                                                                        <td colSpan="10" className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                                                            {lang.no_data}
                                                                        </td>
                                                                    </tr>
                                                                )}
                                                                {dependency.length !== 0 && dependency?.map((row, index) => (
                                                                    <tr key={row}>
                                                                        <td className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                                                            <div className="flex items-center">
                                                                                {row.product_unit.image ? (
                                                                                    <a href={row.product_unit.image} target="_blank" className="w-10 h-10">
                                                                                        <img className="object-cover rounded-full aspect-square" src={row.product_unit.image} alt="Product Image" />
                                                                                    </a>
                                                                                ) : (
                                                                                    <div className="flex items-center justify-center w-10 h-10 rounded-full bg-neutral-100">
                                                                                        <PhotoOff className="w-5 h-5" strokeWidth={1.5} />
                                                                                    </div>
                                                                                )}
                                                                                <div className="ml-4 space-y-1">
                                                                                    <div>{row.product_unit.name}</div>
                                                                                    <div className="font-medium text-neutral-900">{row.product_unit.long_name}</div>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                        <td className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                                                            {row.sales_item_count}
                                                                        </td>
                                                                        {true && (
                                                                            <td class="px-6 py-4 text-xs font-medium text-right whitespace-nowrap">
                                                                                <button type="button" onClick={() => handleRemoveDependency(index)} class={`${false ? 'opacity-50 bg-red-900' : 'bg-red-800'} inline-flex items-center p-1 text-white transition rounded-full active:hover:scale-90`}>
                                                                                    <X className="w-6 h-6" />
                                                                                </button>
                                                                            </td>
                                                                        )}
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <Description value="The dependency must not have more than 1 item." error={errors.dependency} />
                                                    <div className="text-xs">
                                                        <AddDependency data={dependency} onSubmit={(data) => handleAddDependency(data)} />
                                                    </div>
                                                </div>
                                            </fieldset>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}

            {configuration('site') === 'hq' && (
                <>
                    <div className="hidden sm:block" aria-hidden="true">
                        <div className="py-5">
                            <div className="border-t border-gray-200" />
                        </div>
                    </div>

                    <div className="mt-10 sm:mt-0">
                        <div className="md:grid md:grid-cols-3 md:gap-6">
                            <div className="md:col-span-1">
                                <div className="px-4 sm:px-0">
                                    <h3 className="text-lg font-medium leading-6 text-gray-900">Giveaway</h3>
                                    <p className="mt-1 text-sm text-gray-600">Tentukan apakah diskon memiliki barang gratis.</p>
                                </div>
                            </div>
                            <div className="mt-5 md:col-span-2 md:mt-0">
                                <div className="border sm:rounded-xl">
                                    <div className="px-4 py-5 space-y-8 sm:p-6">
                                        <fieldset>
                                            <legend className="sr-only">Giveaway</legend>
                                            <div className="text-base font-medium text-gray-900" aria-hidden="true">
                                                Giveaway
                                            </div>
                                            <div className="mt-4 space-y-4">
                                                <div className="flex items-start">
                                                    <div className="flex items-center h-5">
                                                        <Checkbox id="have_giveaway" checked={haveGiveaway} onChange={() => setHaveGiveaway(!haveGiveaway)} />
                                                    </div>
                                                    <div className="ml-3 text-sm">
                                                        <label htmlFor="have_giveaway" className="font-medium text-gray-700">
                                                            Is discount have giveaway?
                                                        </label>
                                                        <p className="text-gray-500">Check if the discount have some product giveaway.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </fieldset>
                                        {haveGiveaway && (
                                            // Set min width to zero, fix fieldset overflowing parent.
                                            <fieldset className="min-w-0">
                                                <legend className="sr-only">Unit</legend>
                                                <div className="text-base font-medium text-gray-900" aria-hidden="true">
                                                    Unit
                                                </div>
                                                <div className="mt-4 space-y-4">
                                                    <div className="overflow-x-auto border border-neutral-200 rounded-xl">
                                                        <table className="min-w-full overflow-x-auto divide-y divide-neutral-200">
                                                            <thead className="bg-neutral-50 rounded-t-3xl">
                                                                <tr>
                                                                    <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">Product Unit</th>
                                                                    <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">Giveaway Item Count</th>
                                                                    {true && (
                                                                        <th scope="col" className="relative px-6 py-3"><span className="sr-only">{lang.action}</span></th>
                                                                    )}
                                                                </tr>
                                                            </thead>
                                                            <tbody className="bg-white divide-y divide-neutral-200">
                                                                {giveaway.length === 0 && (
                                                                    <tr className="text-center">
                                                                        <td colSpan="10" className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                                                            {lang.no_data}
                                                                        </td>
                                                                    </tr>
                                                                )}
                                                                {giveaway.length !== 0 && giveaway?.map((row, index) => (
                                                                    <tr key={row}>
                                                                        <td className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                                                            <div className="flex items-center">
                                                                                {row.product_unit.image ? (
                                                                                    <a href={row.product_unit.image} target="_blank" className="w-10 h-10">
                                                                                        <img className="object-cover rounded-full aspect-square" src={row.product_unit.image} alt="Product Image" />
                                                                                    </a>
                                                                                ) : (
                                                                                    <div className="flex items-center justify-center w-10 h-10 rounded-full bg-neutral-100">
                                                                                        <PhotoOff className="w-5 h-5" strokeWidth={1.5} />
                                                                                    </div>
                                                                                )}
                                                                                <div className="ml-4 space-y-1">
                                                                                    <div>{row.product_unit.name}</div>
                                                                                    <div className="font-medium text-neutral-900">{row.product_unit.long_name}</div>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                        <td className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                                                            {row.give_away_item_count}
                                                                        </td>
                                                                        {true && (
                                                                            <td class="px-6 py-4 text-xs font-medium text-right whitespace-nowrap">
                                                                                <button type="button" onClick={() => handleRemoveGiveaway(index)} class={`${false ? 'opacity-50 bg-red-900' : 'bg-red-800'} inline-flex items-center p-1 text-white transition rounded-full active:hover:scale-90`}>
                                                                                    <X className="w-6 h-6" />
                                                                                </button>
                                                                            </td>
                                                                        )}
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <Description value="The giveaway must not have more than 1 item." error={errors.giveaway} />
                                                    <div className="text-xs">
                                                        <AddGiveaway data={giveaway} onSubmit={(data) => handleAddGiveaway(data)} />
                                                    </div>
                                                </div>
                                            </fieldset>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}

            <div className="mt-8 text-xs text-right">
                <button type="submit" className="items-center px-6 py-3 text-white transition bg-neutral-800 rounded-xl active:hover:scale-90">
                    <span>{`${_.isEmpty(data) ? lang.create : lang.update}`}</span>
                </button>
            </div>
        </form>
    )
}
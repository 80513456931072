import { ChevronUpDown } from "components/icons/dynamic"

export default function Table({ isLoading, isEmpty, isEmptyOnSearching, headers, columns, data, toggleSort, orderBy, direction }) {
    return (
        <div className="overflow-x-auto border border-neutral-200 rounded-xl">
            <table className="min-w-full overflow-x-auto divide-y divide-neutral-200">
                <thead className="bg-neutral-50 rounded-t-3xl">
                    <tr>
                        {headers.map((row) => (
                            row.isAction ? (
                                <th scope="col" className="relative px-6 py-3"><span className="sr-only">Action</span></th>
                            ) : (
                                <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">
                                    <button className="flex items-center space-x-1 text-xs font-medium text-left uppercase text-neutral-500" onClick={() => toggleSort(row.sortKey)}>
                                        <span>{row.content}</span>
                                        <span><ChevronUpDown direction={orderBy === row.sortKey ? (direction === 'asc' ? 'up' : 'down') : false} className="w-4 h-4" strokeWidth={2} /></span>
                                    </button>
                                </th>
                            )
                        ))}
                    </tr>
                </thead>
                <tbody className="bg-white divide-y divide-neutral-200">
                    {/* When loading */}
                    {isLoading && (
                        <tr className="text-center">
                            <td colSpan="10" className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                Loading Data
                            </td>
                        </tr>
                    )}

                    {/* When there are no list available */}
                    {isEmpty && (
                        <tr className="text-center">
                            <td colSpan="10" className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                No Data Available
                            </td>
                        </tr>
                    )}

                    {/* When there are no list available on searching */}
                    {isEmptyOnSearching && (
                        <tr className="text-center">
                            <td colSpan="10" className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                No Result
                            </td>
                        </tr>
                    )}

                    {data.length > 0 && data.map((row) => (
                        <tr>
                            {columns.map((column) => (
                                column.type === 'action' ? (
                                    <td class="px-6 py-4 text-xs font-medium text-right whitespace-nowrap">
                                        <div class="inline-flex items-center space-x-2">
                                            {column.content(row)}
                                        </div>
                                    </td>
                                ) : (
                                    <td className={`${column.bold ? 'font-medium text-neutral-900' : 'text-neutral-500'} px-6 py-4 text-xs whitespace-nowrap`}>
                                        {column.content(row)}
                                    </td>
                                )
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}
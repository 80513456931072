import { Barcode } from "components/icons/outline"
import Modal from "components/modal"
import { Html5QrcodeScanner } from "html5-qrcode"
import { useEffect, useState } from "react"

import "styles/scanner.css"
import Label from "./label"
import toast from "react-hot-toast"

const Content = ({ onScan }) => {
    const [value, setValue] = useState()

    let scanner

    const onSubmit = () => {
        onScan(value)
    }

    const renderScanner = () => {
        const onScanSuccess = (text) => {
            setValue(text)

            toast.success("Successfully scan image.")
        }

        scanner = new Html5QrcodeScanner('reader', {
            qrbox: 250,
            fps: 10
        })

        scanner.render(onScanSuccess, () => null)
    }

    useEffect(() => {
        if (!scanner) {
            renderScanner()
        } else {
            return () => scanner.clear().catch((error) => console.error("Can't clear scanner", error))
        }
    }, [scanner])

    return (
        <div className="flex flex-col mt-8 space-y-8">
            <div id="reader" className="!border-0"></div>
            {value && (
                <div className="space-y-1">
                    <Label value="Value" />
                    <div className="inline-flex flex-wrap w-full px-2 py-1 text-xs break-all rounded-lg bg-neutral-100">{value}</div>
                </div>
            )}
            <div className="flex items-center space-x-2 text-xs">
                <button type="button" disabled={!value} onClick={onSubmit} className={`${!value ? "opacity-50" : ""} items-center px-6 py-3 text-white transition bg-neutral-800 rounded-xl active:hover:scale-90`}>
                    <span>Submit</span>
                </button>
                {/* <button type="button" disabled={scanner} onClick={onRescan} className="items-center px-6 py-3 transition border border-neutral-200 bg-neutral-50 rounded-xl active:hover:scale-90">
                    <span>Rescan</span>
                </button> */}
            </div>
        </div>
    )
}

export default function BarcodeScanner({ onScan }) {
    const [isOpen, setIsOpen] = useState(false)

    const openModal = () => setIsOpen(true)
    const closeModal = () => setIsOpen(false)

    const handleScan = (data) => {
        onScan(data)

        closeModal()
    }

    return (
        <>
            <button type="button" onClick={openModal} className="inline-flex items-center px-4 mt-1 space-x-2 transition border border-neutral-200 bg-neutral-50 rounded-xl active:hover:scale-90">
                <Barcode className="w-4 h-4" stroke={1.5} />
            </button>
            <Modal isOpen={isOpen} onClose={closeModal} title="Scan Barcode" content={<Content onScan={handleScan} />} />
        </>
    )
}
import { useState, useEffect } from "react"

import ErrorMessage from "components/forms/error-message"
import lodash from "lodash"
import deepdash from "deepdash"
import { lang } from "config"
import Label from "components/forms/label"
import Input from "components/forms/input"
import useProductLineRepositories from "repositories/product-line"
import useProductTypeRepositories from "repositories/product-type"
import useBrandRepositories from "repositories/brand"
import nProgress from "nprogress"
import toast from "react-hot-toast"
import SelectDescription from "components/forms/select-description"
import { usePage } from "contexts/page-context"
import SelectSearchable from "components/forms/select-searchable"

export default function Form({ data, errors, onSubmit }) {
    const _ = deepdash(lodash)

    const { configuration } = usePage()

    const { data: productTypeSelection, isLoading: isLoadingProductTypeSelection } = useProductTypeRepositories({
        paginate: false,
        order_by: "name",
        direction: "asc"
    })

    const { data: brandSelection, isLoading: isLoadingBrandSelection } = useBrandRepositories({
        paginate: false,
        order_by: "name",
        direction: "asc"
    })

    const id = data?.id

    const [productType, setProductType] = useState(data?.product_type?.id ?? null)
    const [brand, setBrand] = useState(data?.brand?.id ?? null)

    const [productTypeObject, setProductTypeObject] = useState()
    const [brandObject, setBrandObject] = useState()

    useEffect(() => {
        if (data) {
            setProductType(data.product_type.id ?? null)
            setBrand(data.brand.id ?? null)
        } else {
            if (!configuration('hierarchy').find((row) => row.key === 'product-type').value && configuration('hierarchy').find((row) => row.key === 'product-brand').default_parent === 'auto' && !isLoadingProductTypeSelection) {
                setProductType(productTypeSelection[0]?.id)
            }
        }
    }, [data, isLoadingProductTypeSelection])

    useEffect(() => {
        if (productType && !isLoadingProductTypeSelection) {
            setProductTypeObject(productTypeSelection.find((row) => row.id === productType))
        }
    }, [productType, isLoadingProductTypeSelection])

    useEffect(() => {
        if (brand && !isLoadingBrandSelection) {
            setBrandObject(brandSelection.find((row) => row.id === brand))
        }
    }, [brand, isLoadingBrandSelection])

    const handleSubmit = (e) => {
        e.preventDefault()

        if (!nProgress.isStarted()) {
            onSubmit({
                id,
                product_type_id: productType,
                product_type: productTypeObject,
                brand_id: brand,
                brand: brandObject
            })
        } else {
            toast.error("Previous action is loading")
        }
    }
    return (
        <form onSubmit={handleSubmit} className="mt-8 space-y-8">
            <div className="mt-10 sm:mt-0">
                <div className="md:grid md:grid-cols-3 md:gap-6">
                    <div className="md:col-span-1">
                        <div className="px-4 sm:px-0">
                            <h3 className="text-lg font-medium leading-6 text-gray-900">{lang.main_form}</h3>
                            <p className="mt-1 text-sm text-gray-600">{lang.please_fill_the_provided_form_input}</p>
                        </div>
                    </div>
                    <div className="mt-5 md:col-span-2 md:mt-0">
                        <div className="border sm:rounded-xl">
                            <div className="px-4 py-5 sm:p-6">
                                <div className="grid grid-cols-2 gap-4">
                                    {configuration('hierarchy').find((row) => row.key === 'product-type').value && (
                                        <div>
                                            <Label htmlFor="product_type" value="Product Type" />
                                            <SelectSearchable filter={(value) => value.name} error={errors.product_type_id} onChange={(value) => setProductType(value)} selection={productTypeSelection} isLoading={isLoadingProductTypeSelection} value={productType} keyValue={(value) => value.id} title={(value) => value.name} placeholder="Select Product Type" />
                                            <ErrorMessage error={errors.product_type_id} />
                                        </div>
                                    )}
                                    <div>
                                        <Label htmlFor="brand" value="Brand" />
                                        <SelectSearchable filter={(value) => value.name} error={errors.brand_id} onChange={(value) => setBrand(value)} selection={brandSelection} isLoading={isLoadingBrandSelection} value={brand} keyValue={(value) => value.id} title={(value) => value.name} placeholder="Select Brand" />
                                        <ErrorMessage error={errors.brand_id} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="mt-8 text-xs text-right">
                <button type="submit" className="items-center px-6 py-3 text-white transition bg-neutral-800 rounded-xl active:hover:scale-90">
                    <span>{`${_.isEmpty(data) ? lang.create : lang.update}`}</span>
                </button>
            </div>
        </form>
    )
}
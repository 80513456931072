import axios from "axios"
import useSWR from "swr"

const fetcher = async (url, params) => {
    return await axios.get(url, { params }).then((res) => res.data.data)
}

const useProductFamilyRepositories = (params) => {
    const { data, error, mutate, isValidating } = useSWR([`${process.env.REACT_APP_INVENTORY_API_URL}/product/family`, params], fetcher)

    return {
        data,
        isLoading: !error && !data,
        error,
        isValidating,
        mutate
    }
}

export default useProductFamilyRepositories